<template>
  <ion-content v-if="product">
    <ion-header>
      <ion-toolbar>
        <div class="d-flex align-center px-2">
          <ion-icon :icon="arrowBackOutline" class="fs-5" @click="closeModal()"></ion-icon>
          <div class="pl-3 fw-700">{{ $t('orderB2b.orderItemDetail') }}</div>
        </div>
      </ion-toolbar>
    </ion-header>

    <div class="ion-padding mt-1 bg-white fs-12">
      <div class="d-flex ion-align-items-center justify-space-between">
        <div>
          <ion-label class="fs-3 medium-9 fw-500" v-if="product.delivery_address"
            >{{ $t('orderB2b.delivery_address') }}:</ion-label
          >
        </div>
        <div>
          <ion-label class="fs-3 fw-700">{{ $t('orderB2b.order') }} #{{ product.order_id }}</ion-label>
        </div>
      </div>

      <div class="d-flex ion-align-items-center justify-space-between" v-if="product.delivery_address">
        <ion-label class="fs-3 mt-2 fw-500" style="line-height: 24px">{{
          product.delivery_address
        }}</ion-label>
      </div>

      <ion-item-divider style="min-height: 1px" class="mt-6 bg-primary" />
    </div>

    <ion-grid class="bg-white mt-3 pr-2">
      <ion-row class="align-center">
        <ion-col size="3">
          <div class="d-flex">
            <td-image
              style="width: 75px; height: 75px"
              class="ml-2"
              :image="product?.image"
              :imagePath="IMAGE_PATH.PRODUCT"
              :imageDefault="DEFAULT_PRODUCT_IMAGE"
            ></td-image>
          </div>
        </ion-col>
        <ion-col size="9" class="fs-3">
          <div class="mr-5">{{ product.sku_name }}</div>
          <div class="d-flex justify-space-between mt-2 mb-2 medium-9">
            <div class="fw-700">
              {{ priceFormatter(currencySymbol, displayPrice(product.sale_unit_price)) }}
            </div>
            <div v-if="!product.is_order_by_weight">x{{ product.total_qty }}</div>
            <div v-else>{{ product.total_qty }} KG</div>
            <div class="fw-700 text-primary">
              {{ priceFormatter(currencySymbol, displayPrice(product.total_price)) }}
            </div>
          </div>
        </ion-col>
        <ion-col>
          <div class="mt-3 mb-3 ion-text-end text-primary">
            {{ product.delivery_status ? product.delivery_status : '-' }}
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
    <div
      class="d-flex"
      :class="product.delivery_status == 'Delivered' ? 'justify-space-between ml-2 mr-2' : 'justify-center'"
    >
      <ion-button
        color="light"
        size="small"
        v-if="product.dispute_status != null"
        @click="openModalDetail(product)"
      >
        <ion-icon slot="start" :icon="repeatOutline" class="mr-2 text-primary"></ion-icon>
        <span class="text-primary">{{ $t('orderB2b.viewDispute') }}</span>
      </ion-button>
      <ion-button color="light" size="small" v-else @click="openModalDetail(product)">
        <ion-icon slot="start" :icon="alertCircleOutline" class="mr-2 text-danger"></ion-icon>
        <span class="text-danger">{{ $t('orderB2b.reportProblem') }}</span>
      </ion-button>
      <ion-button
        color="primary"
        size="small"
        v-if="product.delivery_status == 'Delivered'"
        @click="receivedOrder(product.id)"
      >
        <ion-icon slot="start" :icon="checkmarkCircleOutline" class="mr-2"></ion-icon>
        <span>{{ $t('mark_as_received') }}</span>
      </ion-button>
    </div>

    <ion-modal :is-open="isOpenRef" css-class="order-detail order-item-detail" @didDismiss="setOpen(false)">
      <report-order-item-detail
        @close-modal="onCloseModal"
        @update-status="updateStatus"
        :product="product"
        :item="item"
      ></report-order-item-detail>
    </ion-modal>

    <ion-loading
      :is-open="isOpenLoadingref"
      @didDismiss="setOpenLoading(false)"
      cssClass="custom-loading"
      message=""
      spinner="crescent"
    >
    </ion-loading>
  </ion-content>
</template>

<script>
import { DEFAULT_PRODUCT_IMAGE, IMAGE_PATH } from '@/modules/b2b/constants/index';
import { displayPrice } from '@/modules/b2b/services/libs/helper';
import { ACTIONS } from '@/modules/b2b/store/orders/purchase/actions';
import { priceFormatter } from '@/utils/';
import { toastController } from '@ionic/vue';
import {
  addOutline,
  alertCircleOutline,
  arrowBackOutline,
  checkmarkCircleOutline,
  chevronForwardOutline,
  closeOutline,
  removeOutline,
  repeatOutline,
  trashOutline
} from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { createNamespacedHelpers } from 'vuex';
import ReportOrderItemDetail from './ReportOrderItemDetail';
const { mapActions, mapGetters } = createNamespacedHelpers('b2b/purchase');
export default defineComponent({
  name: 'RepeatOrder',
  components: {
    ReportOrderItemDetail
  },
  data() {
    return {
      product: null
    };
  },
  props: {
    close: {
      type: Function,
      default: () => {}
    },
    orderProduct: {
      type: Object,
      default: null
    },
    item: {
      type: Object,
      default: null
    }
  },
  setup() {
    const user = ref(null);
    const isOpenRef = ref(false);
    const setOpen = (state) => (isOpenRef.value = state);
    // loading
    const isOpenLoadingref = ref(false);
    const setOpenLoading = (state) => (isOpenLoadingref.value = state);

    return {
      user,
      repeatOutline,
      closeOutline,
      arrowBackOutline,
      chevronForwardOutline,
      trashOutline,
      removeOutline,
      addOutline,
      alertCircleOutline,
      isOpenRef,
      setOpen,
      displayPrice,
      checkmarkCircleOutline,
      setOpenLoading,
      isOpenLoadingref,
      priceFormatter,
      IMAGE_PATH,
      DEFAULT_PRODUCT_IMAGE
    };
  },
  computed: {
    ...mapGetters(['status']),
    currencySymbol() {
      return this.user ? this.user.country.currency_symbol : '';
    }
  },
  inject: ['$storage'],
  async mounted() {
    this.product = this.orderProduct;
    this.user = await this.$storage.getUser();
  },
  methods: {
    ...mapActions([ACTIONS.UPDATE_ITEM_RECEIVED]),
    async closeModal() {
      await this.close();
    },
    openModalDetail(product) {
      this.product = product;
      this.setOpen(true);
    },
    updateStatus(params) {
      this.product.dispute_status = 0;
      this.product.dispute_reason = params.reason;
      this.product.dispute_remedy = params.remedy;
      this.setOpen(false);
    },
    onCloseModal() {
      this.setOpen(false);
    },
    async receivedOrder(id) {
      this.setOpenLoading(true);

      await this[ACTIONS.UPDATE_ITEM_RECEIVED]({
        id: id
      });
      this.setOpenLoading(false);

      if (this.status) {
        this.product.delivery_status = 'Received';
        const toast = await toastController.create({
          message: this.$t('update_item_success_mess'),
          position: 'top',
          color: 'primary',
          duration: 2000
        });
        return toast.present();
      }
    }
  }
});
</script>

<style lang="scss" scoped>
ion-content::part(scroll) {
  overflow: hidden;
}
.item {
  font-size: 12px;
  label {
    font-style: italic;
    font-size: 10px;
  }
}
ion-button {
  --border-radius: 50px;
}
.order-item-detail {
  --backdrop-opacity: 0.4 !important;
}
.grey3-color {
  color: var(--ion-color-grey3);
}
.text-danger {
  color: var(--ion-color-danger);
}
</style>
