<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar>
        <ion-grid>
          <ion-row class="align-center" id="row">
            <ion-col size="9" class="d-flex">
              <ion-label class="fs-4 fw-bold">{{ $t('orderB2b.orders') }}</ion-label>
            </ion-col>
            <ion-col size="3" class="header-right">
              <ion-row class="justify-end align-center">
                <ion-icon
                  class="icon-item"
                  :icon="filterOutline"
                  v-if="activeTab === 'purchase'"
                  @click="openFilter()"
                />
                <!-- <notification-badge /> -->
              </ion-row>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-segment mode="md" class="mt-0" :value="activeTab">
              <ion-segment-button value="purchase" @click="changeActiveTabs('purchase')">
                <ion-label>{{ $t('orderB2b.purchase') }}</ion-label>
              </ion-segment-button>
              <!--hide pre order -->
              <!-- <ion-segment-button value="pre-order" @click="changeActiveTabs('pre-order')">
                <ion-label>{{ $t('orderB2b.preOrder') }}</ion-label>
              </ion-segment-button> -->
            </ion-segment>
          </ion-row>
        </ion-grid>
      </ion-toolbar>
    </ion-header>
    <ion-content :scroll-y="isShowSkeleton ? false : true">
      <span v-if="isShowSkeleton">
        <skeleton-order-item v-for="(item, index) in 5" :key="index" />
      </span>
      <OrderItem ref="orderItemComponent" />
    </ion-content>
    <!-- Loading  -->
    <ion-loading
      :is-open="isOpenLoadingref"
      cssClass="custom-loading"
      message=""
      spinner="crescent"
      @didDismiss="setOpenLoading(false)"
    >
    </ion-loading>
  </ion-page>
</template>
<script>
import { ACTIONS } from '@/modules/b2b/store/orders/purchase/actions';
import { modalController } from '@ionic/vue';
import { filterOutline, notificationsOutline } from 'ionicons/icons';
import { defineComponent, inject, ref } from 'vue';
import { createNamespacedHelpers } from 'vuex';
import FilterModal from './FilterModal.vue';
import OrderItem from './components/OrderItem.vue';
import SkeletonOrderItem from './components/SkeletonOrderItem';
const { mapActions } = createNamespacedHelpers('b2b/purchase');

export default defineComponent({
  name: 'Purchase',
  components: {
    OrderItem,
    SkeletonOrderItem
  },
  data() {
    return {
      activeTab: 'purchase',
      isShowSkeleton: true
    };
  },

  setup() {
    const isOpenRef = ref(false);
    const event = ref();
    const setOpen = (state, event) => {
      event.value = event;
      isOpenRef.value = state;
    };
    const isOpenLoadingref = ref(false);
    const setOpenLoading = (state) => (isOpenLoadingref.value = state);
    const ionRouter = inject('navManager');

    return {
      filterOutline,
      notificationsOutline,
      setOpen,
      isOpenRef,
      event,
      isOpenLoadingref,
      setOpenLoading,
      ionRouter
    };
  },

  async ionViewDidEnter() {
    (await this.$refs?.orderItemComponent) && this.$refs?.orderItemComponent.handleGetData();
    this.isShowSkeleton = false;
  },

  methods: {
    ...mapActions([ACTIONS.GET_CUSTOMERS_AND_STATUS]),
    async openFilter(customer) {
      this.setOpenLoading(true);
      this[ACTIONS.GET_CUSTOMERS_AND_STATUS]({});

      const modal = await modalController.create({
        component: FilterModal,
        cssClass: 'filter-order',
        componentProps: {
          customer: customer
        }
      });
      this.setOpenLoading(false);
      modal.present();

      const { role } = await modal.onWillDismiss();

      if (role === 'apply') {
        this.isShowSkeleton = true;
        setTimeout(() => {
          this.isShowSkeleton = false;
        }, 500);
      }
    },
    changeActiveTabs(value) {
      this.ionRouter.navigate({
        routerDirection: 'none',
        routerLink: '/b2b/order/' + value
      });
    },
    goToNotificationPage() {
      this.ionRouter.navigate({
        routerLink: '/b2b/notifications/all'
      });
    }
  }
});
</script>
<style lang="scss">
.btn-icon {
  --padding-start: 0px;
  --padding-end: 0px;
}

.my-popover {
  --max-width: 120px;
  --backdrop-opacity: 0;
}

.my-popover .popover-content {
  margin-top: 10px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

ion-segment {
  width: 100%;
  margin-top: 1rem;
  border-bottom: 1px solid #f5f5f5;
  ion-label {
    text-transform: capitalize;
    font-size: 12px;
    margin-bottom: 0px;
    margin-top: 10px;
  }
}
.text-bold {
  font-weight: bold;
  color: #000;
}

ion-modal.select-company {
  --width: 90%;
  --height: 48%;
  .sc-ion-modal-ios {
    border-radius: 4px;
  }
}
.icon-item {
  font-size: 24px;
  padding-right: 10px;
}
@media only screen and (max-width: 360px) {
  .icon-item {
    font-size: 20px;
    padding-right: 4px;
  }
}
@media only screen and (max-width: 280px) {
  .btn-notify {
    width: 22px !important;
  }
}
#row {
  height: 50px;
}
</style>
