<template>
  <ion-page v-if="viewPrice">
    <div class="ion-padding">
      <ion-row class="align-center">
        <ion-col size="4" class="ion-no-padding fs-15px">
          <ion-text class="text-bold fs-3">{{ $t('orderB2b.order') }} #{{ orderEditDetail.id }}</ion-text>
        </ion-col>
        <ion-col class="ion-no-padding medium fs-3">
          <div class="ion-text-end">
            <ion-chip color="primary" class="ion-no-margin disable-click">
              <ion-icon :icon="repeatOutline"></ion-icon>
              <ion-label>{{ orderEditDetail.orderStatus.name }}</ion-label>
            </ion-chip>
          </div>
        </ion-col>
      </ion-row>

      <ion-row class="mt-2 align-center">
        <ion-col size="8" class="ion-no-padding medium fs-3">
          <div class="d-flex">{{ $t('delivery_time') }}</div>

          <div class="d-flex mt-1">{{ showDeliveryDate }} {{ showDeliveryTime() || '' }}</div>
        </ion-col>

        <ion-col size="4" class="ion-no-padding fs-15px" v-if="!Paid">
          <div class="fs-3 primary text-end" @click="chooseTime()">{{ $t('change_time') }}</div>
        </ion-col>
      </ion-row>
    </div>

    <ion-item-divider style="min-height: 1px" class="bg-primary" />

    <ion-content>
      <ion-grid class="ml-2 mr-2" v-if="viewPrice">
        <ion-row class="d-block" v-for="(item, index) in viewPrice || []" :key="index">
          <div v-if="item.active && item.sku_id > 0 && ![-3, 10].includes(item.order_item_status_id)">
            <ion-col size="12">
              <div class="title d-flex fs-15px" style="color: black; line-height: 22px; width: 65%">
                {{ item.sku.name }}
              </div>
              <div class="d-flex justify-space-between align-center">
                <div style="width: 50%">
                  <div class="medium mt-1 mb-1 fs-12px">
                    {{ priceFormatter(currencySymbol, item.sale_unit_price.toFixed(2)) }} ({{
                      item.total_qty
                    }}
                    {{ item.sku.oom.name }}(s))
                  </div>
                  <div class="mt-1 mb-1">
                    <tooltip v-if="item.sku.is_catch_weight" />
                  </div>
                  <div v-if="item.catch_weight" class="medium mt-1 mb-1 fs-12px text-italic">
                    <span class="text-weight-bold">{{ $t('weight') }}:</span>
                    {{ item.catch_weight }}
                  </div>
                  <div
                    class="fw-700"
                    color="primary"
                    @click="
                      orderEditDetail.orderItems[0]?.supplier.tenant.class_id != 1
                        ? null
                        : openUpdatePriceForm(item)
                    "
                  >
                    {{ priceFormatter(currencySymbol, item.total_price.toFixed(2)) }}
                  </div>
                </div>

                <div class="count d-flex justify-end">
                  <div class="text-end" style="max-width: 35px">
                    <ion-button
                      fill="clear"
                      class="ion-no-padding-end"
                      color="danger"
                      size="small"
                      v-if="unPaid"
                      @click="confirmRemoveItem(index)"
                    >
                      <ion-icon slot="icon-only" :icon="closeOutline"></ion-icon>
                    </ion-button>
                  </div>
                  <div v-if="item.is_order_by_weight" class="d-flex align-self-center h-input">
                    <ion-input
                      :readonly="Paid"
                      v-model="item.total_weight"
                      type="number"
                      onKeyDown="javascript: return event.keyCode == 69 ? false : true"
                      inputmode="decimal"
                      class="input-weight"
                      @ionChange="onChangeTotalWeight(index, item.total_weight)"
                      @blur="onBlurTotalWeight(index, item.total_weight)"
                    />
                    <span class="ml-1 name">KG</span>
                  </div>
                  <div class="d-flex cnt-weight h-100" v-if="!item.is_order_by_weight">
                    <div class="align-self-center text-center">
                      <ion-button
                        v-if="unPaid"
                        fill="clear"
                        size="small"
                        @click.stop="
                          item.total_qty == item.sku?.increment_qty
                            ? null
                            : onChangeQty(index, item.total_qty - item.sku?.increment_qty)
                        "
                      >
                        <ion-icon slot="icon-only" :icon="removeCircle"></ion-icon>
                      </ion-button>
                    </div>
                    <div size="5" class="align-self-center text-center">
                      <input v-model.number="item.total_qty" readonly type="number" class="input-weight" />
                    </div>
                    <div class="align-self-center text-center">
                      <ion-button
                        v-if="unPaid"
                        fill="clear"
                        size="small"
                        @click.stop="onChangeQty(index, item.total_qty + item.sku?.increment_qty)"
                      >
                        <ion-icon slot="icon-only" :icon="addCircle"></ion-icon>
                      </ion-button>
                    </div>
                  </div>
                </div>
              </div>
            </ion-col>
            <ion-col size="12" class="d-flex"> </ion-col>
          </div>
          <div v-else></div>
        </ion-row>
      </ion-grid>

      <!-- <ion-item-divider style="min-height: 1px" class="bg-grey mt-1 mb-1" /> -->
    </ion-content>

    <div class="amount-footer">
      <ion-item-divider style="min-height: 1px" class="bg-primary" />

      <div class="pl-3 pr-3 mt-2 mb-2" style="font-size: 12px">
        <ion-textarea
          rows="1"
          v-model="description"
          :placeholder="$t('note_to_driver') + ' ' + `(${$t('optional')})`"
        ></ion-textarea>
      </div>
      <ion-item-divider style="min-height: 1px" />

      <div class="ion-padding" style="font-size: 12px">
        <ion-row class="ion-align-items-center">
          <ion-col class="ion-no-padding medium"> {{ $t('orderB2b.subTotal') }}</ion-col>
          <ion-col class="ion-no-padding ion-text-end medium">
            {{ priceFormatter(currencySymbol, totalOrder) }}</ion-col
          >
        </ion-row>
        <ion-row class="ion-align-items-center pt-1">
          <ion-col class="ion-no-padding medium"> {{ $t('orderB2b.tax') }} </ion-col>
          <ion-col class="ion-no-padding ion-text-end medium">
            {{ priceFormatter(currencySymbol, totalTax) }}
          </ion-col>
        </ion-row>
        <ion-row class="ion-align-items-center pt-1">
          <ion-col class="ion-no-padding medium"> {{ $t('discount') }} </ion-col>
          <ion-col class="ion-no-padding ion-text-end medium">
            {{ priceFormatter(currencySymbol, discount) }}
          </ion-col>
        </ion-row>

        <ion-item-divider style="min-height: 1px" class="mt-1 mb-1" />

        <ion-row class="ion-align-items-center pt-2">
          <ion-col class="ion-no-padding fs-3 fw-700 medium-9"> {{ $t('orderB2b.grandTotal') }}</ion-col>
          <ion-col class="ion-no-padding ion-text-end primary fs-3 fw-700">
            <div class="d-flex total-price">
              <div v-if="Paid" style="color: rgb(1, 105, 1)">{{ $t('paid') }} &nbsp;</div>
              <div v-if="unPaid" style="color: red">{{ $t('un_paid') }} &nbsp;</div>
              <div>{{ priceFormatter(currencySymbol, grandTotal) }}</div>
            </div>
          </ion-col>
        </ion-row>
      </div>
    </div>
    <ion-row class="ion-no-padding">
      <ion-col class="ion-no-padding">
        <ion-button class="ion-no-margin fs-2" expand="full" color="danger" @click="confirmCancelOrder">{{
          $t('cancelOrder')
        }}</ion-button>
      </ion-col>
      <ion-col v-if="!Paid" class="ion-no-padding">
        <ion-button
          class="ion-no-margin fs-2"
          expand="full"
          color="primary"
          :disabled="isDisable"
          @click="confirmUpdateOrder"
          >{{ $t('updateOrder') }}</ion-button
        >
      </ion-col>
    </ion-row>

    <ion-modal :is-open="isOpenTimeRef" css-class="modal-time" @didDismiss="setOpenTime(false)">
      <modal-delivery-time
        :items="items"
        :orderEditDetail="orderEditDetail"
        :deliveryDate="currentDateTime['date']"
        :deliveryTime="currentDateTime['time']"
        @confirm="confirmDateTime"
        @close-modal="setOpenTime(false)"
      ></modal-delivery-time>
    </ion-modal>

    <ion-modal :is-open="isOpenPriceRef" css-class="modal-price" @didDismiss="setOpenPrice(false)">
      <modal-update-price
        @confirm="confirmDateTime"
        :product="priceOrder"
        @close-modal="setOpenPrice(false)"
      ></modal-update-price>
    </ion-modal>

    <ion-loading
      :is-open="isOpenLoadingref"
      @didDismiss="setOpenLoading(false)"
      cssClass="custom-loading"
      message=""
      spinner="crescent"
    >
    </ion-loading>
  </ion-page>
</template>

<script>
import { IonInput, alertController, toastController } from '@ionic/vue';
import {
  addCircle,
  carOutline,
  closeOutline,
  removeCircle,
  removeCircleOutline,
  repeatOutline
} from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import ModalDeliveryTime from './ModalDeliveryTime.vue';
import ModalUpdatePrice from './ModalUpdatePrice.vue';

import Tooltip from '@/components/molecules/tooltip/Tooltip.vue';
import { displayPrice, getTomorrow } from '@/modules/b2b/services/libs/helper';
import { ACTIONS } from '@/modules/b2b/store/orders/purchase/actions';
import { priceFormatter } from '@/utils/';
import dayjs from 'dayjs';
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters } = createNamespacedHelpers('b2b/purchase');
export default defineComponent({
  name: 'UpdateOrder',
  components: {
    ModalDeliveryTime,
    ModalUpdatePrice,
    Tooltip,
    IonInput
  },
  props: {
    orderEditDetail: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      description: null,
      priceOrder: null,
      isDelete: false,
      removeItemList: [],
      isDisable: false
    };
  },
  setup() {
    const isOpenTimeRef = ref(false);
    const setOpenTime = (state) => (isOpenTimeRef.value = state);

    const isOpenPriceRef = ref(false);
    const setOpenPrice = (state) => (isOpenPriceRef.value = state);
    const updateOrderSuccess = ref({});
    const currentDateTime = ref({
      date: '',
      time: ''
    });
    // loading
    const isOpenLoadingref = ref(false);
    const setOpenLoading = (state) => (isOpenLoadingref.value = state);

    const orderQuantity = ref(1);
    const user = ref(null);
    const items = ref([]);

    return {
      repeatOutline,
      removeCircle,
      addCircle,
      carOutline,
      closeOutline,
      removeCircleOutline,
      isOpenTimeRef,
      setOpenTime,
      currentDateTime,
      updateOrderSuccess,
      orderQuantity,
      user,
      isOpenPriceRef,
      setOpenPrice,
      setOpenLoading,
      isOpenLoadingref,
      priceFormatter,
      items
    };
  },
  computed: {
    ...mapGetters(['viewPrice', 'status', 'message']),
    currencySymbol() {
      return this.user ? this.user.country.currency_symbol : '';
    },
    discount() {
      let value = 0;
      if (this.orderEditDetail)
        this.viewPrice.forEach((item) => {
          if (item.sku_id < 0 && ![-3, 10].includes(item.order_item_status_id))
            value += Number(item.total_price);
        });

      return displayPrice(value);
    },

    totalOrder() {
      let total = 0;
      this.viewPrice.forEach((product) => {
        if (product.active && product.sku_id > 0 && ![-3, 10].includes(product.order_item_status_id)) {
          total += Number(product.total_price);
        }
      });
      return displayPrice(total);
    },

    totalTax() {
      let gst = 0;
      this.viewPrice.forEach((product) => {
        if (product.active && product.sku_id > 0 && ![-3, 10].includes(product.order_item_status_id)) {
          gst += Number(displayPrice((Number(product.tax_rate) / 100) * product.total_price));
        }
      });
      return displayPrice(gst);
    },
    grandTotal() {
      return displayPrice(Number(this.totalOrder) + Number(this.totalTax) + Number(this.discount));
    },
    showDeliveryDate() {
      return this.formatDeliveryDate(
        this.currentDateTime.date ? this.currentDateTime.date : this.orderEditDetail.delivery_date
      );
    },
    showDeliveryTime() {
      return () =>
        this.currentDateTime.time ? this.currentDateTime.time : this.orderEditDetail.delivery_time;
    },
    Paid() {
      return (
        this.orderEditDetail.orderStatus.name.toLowerCase() == 'processing' && this.orderEditDetail.is_paid
      );
    },
    unPaid() {
      return (
        this.orderEditDetail.orderStatus.name.toLowerCase() == 'processing' && !this.orderEditDetail.is_paid
      );
    }
  },
  inject: ['$storage'],
  async mounted() {
    this.description = this.orderEditDetail.description;
    this.user = await this.$storage.getUser();
    this.handleGetSkuId();
  },
  methods: {
    ...mapActions([ACTIONS.UPDATE_ORDER_B2B, ACTIONS.CANCEL_ORDER_B2B]),

    handleGetSkuId() {
      for (const value of this.viewPrice) {
        this.items.push({
          quantity: value.total_qty,
          sku_id: value.sku.id
        });
      }
    },
    onBlurTotalWeight(idx, newWeight) {
      if (!newWeight) {
        newWeight = 1;
        this.onChangeTotalWeight(idx, newWeight);
      }
    },
    onChangeTotalWeight(idx, newWeight) {
      newWeight = `${newWeight}`.slice(0, 8);
      if (+newWeight === 0) {
        this.isDisable = true;
      } else {
        this.isDisable = false;
        let pattern = /(\d+\.\d\d)/g;
        let regex = pattern.exec(newWeight);
        if (regex) {
          newWeight = parseFloat(regex[1]);
        }
        this.viewPrice[idx].total_weight = newWeight;
        this.viewPrice[idx].total_qty = newWeight;
        this.viewPrice[idx].catch_weight = newWeight + ' Kg';
        const newPriceTotal = Number(displayPrice(newWeight * this.viewPrice[idx].sale_unit_price));
        this.viewPrice[idx].total_price = newPriceTotal;
        const newTax = Number(displayPrice((this.viewPrice[idx].tax_rate / 100) * newPriceTotal));
        this.viewPrice[idx].tax = newTax;
      }
    },

    formatDeliveryDate(date = null) {
      if (!date) return null;
      if (dayjs(date).format('YYYY-MM-DD') === getTomorrow())
        return this.$t('tomorrow') + ', ' + dayjs(date).format('DD MMM YYYY');
      return dayjs(date).format('ddd, D MMM YYYY');
    },

    onChangeQty(index, total_qty) {
      const item = this.viewPrice[index];
      this.viewPrice[index].total_qty = total_qty;
      this.viewPrice[index].total_weight = Number(item.weight) * total_qty;
      this.viewPrice[index].total_price = Number(displayPrice(total_qty * Number(item.sale_unit_price)));
      this.viewPrice[index].tax = Number(displayPrice((Number(item.tax_rate) / 100) * item.total_price));
      this.items[index].quantity = total_qty;
    },
    chooseTime() {
      this.currentDateTime = {
        date: this.currentDateTime.date ? this.currentDateTime.date : this.orderEditDetail.delivery_date,
        time: this.currentDateTime.time ? this.currentDateTime.time : this.orderEditDetail.delivery_time
      };
      this.setOpenTime(true);
    },
    confirmDateTime(data) {
      this.currentDateTime = {
        date: data.deliveryDate,
        time: data.deliveryTime
      };
      this.setOpenTime(false);
    },
    async openUpdatePriceForm(item) {
      this.priceOrder = item;
      this.setOpenPrice(true);
    },
    wrapWord(text) {
      return this.$t(text);
    },
    async presentAlert(header, message, cancelButtonText, confirmButtonText, confirmHandler) {
      const alert = await alertController.create({
        mode: 'ios',
        header,
        message,
        buttons: [
          {
            text: cancelButtonText,
            role: 'cancel'
          },
          ...(confirmButtonText
            ? [
                {
                  text: confirmButtonText,
                  handler: confirmHandler
                }
              ]
            : [])
        ]
      });
      await alert.present();
    },
    async confirmRemoveItem(index) {
      if (this.viewPrice.length == 1) {
        this.confirmCancelOrder();
      } else {
        await this.presentAlert(
          this.wrapWord('are_you_sure'),
          this.wrapWord('remove_mess'),
          this.wrapWord('no'),
          this.wrapWord('yes'),
          () => {
            let payload = {
              isDelete: true,
              orderItemId: this.viewPrice[index].id,
              qty: this.viewPrice[index].total_qty,
              origin_unit_price: Number(this.viewPrice[index].total_price),
              sale_unit_price: Number(this.viewPrice[index].sale_unit_price),
              weight: null,
              total_weight: this.viewPrice[index].total_weight,
              catch_weight: this.viewPrice[index].catch_weight,
              status_note: this.viewPrice[index].status_note,
              order_item_status_id: this.viewPrice[index].order_item_status_id,
              is_order_by_weight: this.viewPrice[index].is_order_by_weight
            };
            this.removeItemList.push(payload);
            this.viewPrice.splice(index, 1);
          }
        );
      }
    },

    async confirmUpdateOrder() {
      await this.presentAlert(
        this.wrapWord('updateOrder'),
        this.wrapWord('confirm_update_order_mess'),
        this.wrapWord('no'),
        this.wrapWord('yes'),
        () => {
          this.updateOrder();
        }
      );
    },

    async confirmCancelOrder() {
      await this.presentAlert(
        this.wrapWord('cancelOrder'),
        this.wrapWord('order.cancel_order_confirm'),
        this.wrapWord('no'),
        this.wrapWord('yes'),
        () => {
          this.cancelOrder();
        }
      );
    },

    async updateOrder() {
      let itemRemove = JSON.parse(JSON.stringify(this.removeItemList));
      let simplified = this.viewPrice.map((x) => {
        return {
          isDelete: false,
          orderItemId: x.id,
          qty: x.total_qty,
          origin_unit_price: Number(x.total_price),
          sale_unit_price: Number(x.sale_unit_price),
          weight: x.weight,
          total_weight: x.total_weight,
          catch_weight: x.catch_weight,
          status_note: x.status_note,
          order_item_status_id: x.order_item_status_id,
          is_order_by_weight: x.is_order_by_weight
        };
      });

      const payload = {
        orderId: this.orderEditDetail.id,
        orderVersion: this.orderEditDetail.version,
        deliveryDate: this.currentDateTime.date
          ? dayjs(this.currentDateTime.date).format('YYYY-MM-DD')
          : dayjs(this.orderEditDetail.delivery_date).format('YYYY-MM-DD'),
        deliveryTime: this.currentDateTime.time
          ? this.currentDateTime.time
          : this.orderEditDetail.delivery_time,
        description: this.description,
        orderItems: [...simplified, ...itemRemove]
      };

      if (!payload.deliveryDate) delete payload.deliveryDate;

      this.setOpenLoading(true);
      await this[ACTIONS.UPDATE_ORDER_B2B](payload);
      this.setOpenLoading(false);

      if (this.status) {
        this.openToast(this.$t('orderB2b.updateOrderSuccess'));
        this.$emit('update-data');
      } else {
        await this.presentAlert(this.message, '', this.wrapWord('close'));
        this.$emit('update-data');
      }
    },
    async cancelOrder() {
      this.setOpenLoading(true);
      await this[ACTIONS.CANCEL_ORDER_B2B]({
        orderId: this.orderEditDetail.id,
        orderVersion: this.orderEditDetail.version
      });
      this.setOpenLoading(false);
      if (this.status) {
        this.openToast(this.$t('order.cancel_order_succeed'));
        this.$emit('update-data');
      } else {
        await this.presentAlert(this.message, '', this.wrapWord('close'));
        this.$emit('update-data');
      }
    },
    async openToast(message) {
      const toast = await toastController.create({
        message,
        position: 'top',
        color: 'primary',
        duration: 2000
      });
      return toast.present();
    }
  }
});
</script>

<style lang="scss" scoped>
$small-screen: 350px;

.fs-15px {
  line-height: 22px;
  color: #9e9e9e;
}

ion-button.count {
  --border-radius: 14px;
}

.cnt-weight {
  border: none;
  border-radius: 5px;
}
.input-weight {
  width: 100%;
  border: 0;
  text-align: center;
  border-bottom: 1px dashed var(--ion-color-primary);
  background-color: #fff;
  --padding-bottom: 30px;
  @media screen and (max-width: $small-screen) {
    width: 30px;
  }
  &:focus {
    outline: none;
  }
}
.add-weight {
  position: relative;
  max-width: 120px;
  padding-left: 20px;
  .name {
    position: absolute;
    right: 0;
  }
}
.count {
  width: 65%;
}
.total-price {
  justify-content: flex-end;
}
.h-input {
  height: 1rem;
}
</style>
