<template>
  <ion-page v-if="isShow">
    <div class="ion-padding">
      <ion-row>
        <ion-col size="9" class="ion-no-padding fs-3">{{ $t('orderB2b.order') }} #{{ item.id }}</ion-col>
        <ion-col size="3" class="ion-no-padding" style="text-align: right">
          <ion-icon :icon="closeOutline" class="icon-small" @click="closeModal()"></ion-icon>
        </ion-col>
      </ion-row>
      <ion-row class="ion-align-items-center">
        <ion-col size="8" class="ion-no-padding fs-15 d-flex">
          {{ formatTime(item.delivery_date) }}
          <span>&nbsp;{{ item.delivery_time }}</span>
        </ion-col>
        <ion-col size="4" class="ion-no-padding">
          <div class="ion-text-end">
            <ion-chip
              class="disable-click"
              :color="item.orderStatus.name === 'Cancelled' ? 'danger' : 'primary'"
            >
              <ion-icon :icon="repeatOutline" class="fs-sm"></ion-icon>
              <ion-label class="fs-sm">{{ item.orderStatus.name }}</ion-label>
            </ion-chip>
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="ion-no-padding fs-15">{{ item.orderItems[0].supplier?.name }}</ion-col>
      </ion-row>
    </div>

    <ion-item-divider style="min-height: 1px" class="bg-primary" />

    <ion-content>
      <ion-grid>
        <ion-row
          class="ion-padding item sm-no-padding"
          v-for="(product, index) in detailPurchase || []"
          :key="index"
        >
          <div
            class="d-flex w-100"
            v-if="
              product.sku_id > 0 && ([10, 15].indexOf(product.order_item_status_id) > -1 || product.active)
            "
          >
            <ion-col size="3">
              <td-image
                class="image-product"
                :image="product.image"
                :imagePath="IMAGE_PATH.PRODUCT"
                :imageDefault="DEFAULT_PRODUCT_IMAGE"
              ></td-image>
            </ion-col>
            <ion-col size="9" class="pl-2">
              <div class="title fs-15" style="color: black; line-height: 22px">
                {{ product.sku_name }}
              </div>
              <div v-if="product.catch_weight" class="medium text-italic fs-12">
                <span class="text-weight-bold">{{ $t('weight') }}:</span>
                {{ product.catch_weight }}
              </div>
              <tooltip v-if="product.is_catch_weight" />
              <div class="d-flex mt-2 mb-1 fs-3 justify-space-between">
                <div class="fw-700 grey-color">
                  {{ formatNumber(product.sale_unit_price) }}
                </div>
                <div v-if="!product.is_order_by_weight">x{{ product.total_qty }}</div>
                <div v-else>{{ product.total_qty }} KG</div>
                <div class="fw-700 text-primary">
                  {{ formatNumber(product.total_price) }}
                </div>
              </div>
              <div class="d-flex mt-2 mb-1 fs-3 justify-end">
                <ion-chip class="mx-0 disable-click" v-if="product.order_item_status">
                  <ion-label class="primary fs-12px">{{ product.order_item_status }}</ion-label>
                </ion-chip>
              </div>
              <div class="d-flex fs-3 justify-space-between" v-if="product.order_item_status_id === 15">
                <ion-chip color=" mx-0" :class="item.order_status_id == 1 ? 'bg-primary' : 'bg-grey'">
                  <ion-label
                    style="color: white"
                    class="fs-12px"
                    @click="item.order_status_id == 1 ? onConfirmQtyAmendment(product.id) : null"
                    >{{ $t('confirm_increased_quantity') }}</ion-label
                  >
                </ion-chip>
              </div>
            </ion-col>
          </div>
        </ion-row>
        <ion-row class="ion-padding sm-no-padding" v-if="getCancelledBy">
          <ion-col size="12">
            <div class="message-bottom flex-1 py-1 px-2">
              <ion-text>
                <p class="fs-2 fw-600 py-1 my-0">Cancelled by {{ getCancelledBy }}</p>
                <div class="fs-13">
                  <p class="pb-1 my-0">Reason:</p>
                  <p class="pb-1 my-0">{{ getReasons }}</p>
                </div>
              </ion-text>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>

    <div class="amount-footer" v-if="detailPurchase">
      <ion-item-divider style="min-height: 1px" class="bg-primary" />

      <div class="ion-padding">
        <ion-row class="mb-2">
          <ion-col>
            <div class="fs-11 fw-700">{{ $t('note_to_driver') }} ({{ $t('optional') }})</div>
            <span class="ion-text-start fs-1 medium">{{
              detail[0].description ? detail[0].description : '-'
            }}</span>
          </ion-col>
        </ion-row>
        <ion-item-divider style="min-height: 1px" />

        <ion-row class="ion-align-items-center pt-3">
          <ion-col class="ion-no-padding title-left"> {{ $t('orderB2b.total') }} </ion-col>
          <ion-col class="ion-no-padding ion-text-end text-primary fw-700">
            {{ subTotalFormatted }}
          </ion-col>
        </ion-row>
        <ion-row class="ion-align-items-center pt-1">
          <ion-col class="ion-no-padding title-left"> {{ $t('orderB2b.tax') }} </ion-col>
          <ion-col class="ion-no-padding ion-text-end text-primary fw-700">
            {{ taxFormatted }}
          </ion-col>
        </ion-row>
        <ion-row class="ion-align-items-center pt-1">
          <ion-col class="ion-no-padding title-left"> {{ $t('orderB2b.discount') }} </ion-col>
          <ion-col class="ion-no-padding ion-text-end grey-color">
            {{ discountFormatted }}
          </ion-col>
        </ion-row>
        <ion-row class="ion-align-items-center pt-1">
          <ion-col class="ion-no-padding title-left"> {{ $t('orderB2b.grandTotal') }} </ion-col>
          <ion-col class="ion-no-padding ion-text-end text-primary fw-700">
            {{ grandTotal }}
          </ion-col>
        </ion-row>
      </div>

      <ion-loading
        :is-open="isOpenLoadingref"
        @didDismiss="setOpenLoading(false)"
        cssClass="custom-loading"
        message=""
        spinner="crescent"
      >
      </ion-loading>
    </div>
  </ion-page>
</template>

<script>
import Tooltip from '@/components/molecules/tooltip/Tooltip.vue';
import { DEFAULT_PRODUCT_IMAGE, IMAGE_PATH } from '@/modules/b2b/constants/index';
import { ACTIONS } from '@/modules/b2b/store/orders/purchase/actions';
import { MERCHANT_APP_NAME, USER_TYPE_ID } from '@/modules/shared/constants';
import { priceFormatter } from '@/utils/';
import { alertController, modalController } from '@ionic/vue';
import dayjs from 'dayjs';
import { closeOutline, repeatOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { createNamespacedHelpers } from 'vuex';
import OrderItemDetail from './OrderItemDetail';
const { mapActions, mapGetters } = createNamespacedHelpers('b2b/purchase');

export default defineComponent({
  name: 'order-detail',
  components: {
    Tooltip
  },
  data() {
    return {
      isShow: false,
      productImages: {}
    };
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    closeDetail: {
      type: Function,
      required: true
    }
  },
  setup() {
    const user = ref(null);
    const image = ref(null);

    // loading
    const isOpenLoadingref = ref(false);
    const setOpenLoading = (state) => (isOpenLoadingref.value = state);

    return {
      user,
      image,
      repeatOutline,
      closeOutline,
      setOpenLoading,
      isOpenLoadingref,
      IMAGE_PATH,
      DEFAULT_PRODUCT_IMAGE
    };
  },
  computed: {
    ...mapGetters(['detailPurchase', 'itemAmendments', 'status']),
    currencySymbol() {
      return this.user ? this.user.country.currency_symbol : '';
    },

    formatDateTime() {
      return (value) => {
        return value ? new Date(value).toLocaleString() : null;
      };
    },
    detail() {
      return this.detailPurchase;
    },
    tax() {
      return this.detailPurchase
        .filter((v) => v.active && ![-3, 10].includes(v.order_item_status_id))
        .map((v) => parseFloat(v.tax))
        .reduce((total, v) => {
          return total + v;
        }, 0);
    },
    subTotal() {
      return this.detailPurchase
        .filter((v) => v.active && v.total_price > 0 && ![-3, 10].includes(v.order_item_status_id))
        .map((v) => parseFloat(v.total_price))
        .reduce((total, v) => {
          return total + v;
        }, 0);
    },
    discount() {
      return this.detailPurchase
        .filter((v) => v.active && v.total_price < 0 && ![-3, 10].includes(v.order_item_status_id))
        .map((v) => parseFloat(v.total_price))
        .reduce((total, v) => {
          return total + v;
        }, 0);
    },
    taxFormatted() {
      return priceFormatter(this.currencySymbol, this.tax.toFixed(2));
    },
    subTotalFormatted() {
      return priceFormatter(this.currencySymbol, this.subTotal.toFixed(2));
    },
    discountFormatted() {
      return priceFormatter(this.currencySymbol, this.discount.toFixed(2));
    },
    grandTotal() {
      const total = this.subTotal + this.tax + this.discount;
      return priceFormatter(this.currencySymbol, total.toFixed(2));
    },
    getCancelledBy() {
      const userTypeId = this.item?.orderLogs?.[0]?.user?.user_type_id;
      const status = this.item?.orderStatus.name;
      let cancelledBy = '';
      if (
        (userTypeId === USER_TYPE_ID.ADMIN || userTypeId === USER_TYPE_ID.SALE) &&
        status === 'Cancelled' &&
        this.item.app_name !== MERCHANT_APP_NAME
      ) {
        cancelledBy = 'Supplier';
      } else if (status === 'Cancelled' && this.item.app_name === MERCHANT_APP_NAME) {
        cancelledBy = this.item.app_name;
      }
      return cancelledBy;
    },
    getReasons() {
      if (this.item.reason) {
        return `${this.item.reason_cancel} - ${this.item.reason}`;
      }
      const message = this.item.reason_cancel;
      return message;
    }
  },
  inject: ['$storage'],
  async mounted() {
    this.user = await this.$storage.getUser();
    this.getData();
  },
  methods: {
    ...mapActions([ACTIONS.GET_PURCHASE_DETAIL, ACTIONS.GET_ORDER_ITEM_AMENDMENTS, ACTIONS.UPDATE_AMENDMENT]),
    async closeModal() {
      await this.closeDetail();
    },
    formatNumber(number) {
      return priceFormatter(this.currencySymbol, number.toFixed(2));
    },
    formatTime(params) {
      return dayjs(params).format('D MMM YYYY ');
    },
    async getData() {
      this.setOpenLoading(true);
      await this[ACTIONS.GET_PURCHASE_DETAIL]({ orderId: this.item.id });
      this.setOpenLoading(false);
      if (this.status) {
        this.isShow = true;
      }
    },

    async openModalDetail(item, product) {
      const modal = await modalController.create({
        component: OrderItemDetail,
        cssClass: 'order-item-detail',
        componentProps: {
          title: 'Delivery Data & Time',
          orderProduct: product,
          item: item,
          close: async () => {
            await modal.dismiss();
          }
        }
      });
      return modal.present();
    },
    async onConfirmQtyAmendment(id) {
      this.setOpenLoading(true);
      await this[ACTIONS.GET_ORDER_ITEM_AMENDMENTS]({
        orderItemId: id,
        isApproved: 0
      });
      this.setOpenLoading(false);

      const alert = await alertController.create({
        cssClass: 'my-custom-class',
        header: 'Quality Amendment',
        message: `${this.itemAmendments.created_by} (${this.itemAmendments.user_type}) has increased this item's quantity from ${this.itemAmendments.old_qty} to ${this.itemAmendments.new_qty} <br/><br/> Reason: ${this.itemAmendments.reason} <br/><br/>  Do you approve this changes ?`,
        buttons: [
          {
            text: this.$t('approve'),
            role: 'No',
            cssClass: 'primary',
            handler: () => {
              this.amendmentApproval();
            }
          },
          {
            text: this.$t('reject'),
            role: 'Reject',
            cssClass: 'danger',
            handler: () => {
              this.amendmentApproval();
            }
          },
          {
            text: this.$t('cancel'),
            role: 'Cancel',
            cssClass: 'secondary',
            handler: () => {}
          }
        ]
      });
      await alert.present();
    },
    async amendmentApproval() {
      this.setOpenLoading(true);
      await this[ACTIONS.UPDATE_AMENDMENT]({
        amendmentId: this.itemAmendments.id,
        isApproved: this.user.id
      });
      this.setOpenLoading(false);

      await this.getData();
    }
  }
});
</script>

<style lang="scss" scoped>
.fs-15 {
  font-size: 15px;
  line-height: 22px;
  color: #9e9e9e;
}
.fs-11 {
  font-size: 11px;
}
.grey-color {
  color: var(--ion-color-grey5);
}
.item {
  border-bottom: 1px solid var(--ion-color-grey);
}
.bg-grey {
  background-color: var(--ion-color-grey);
}
.image-product {
  width: 75px;
  height: 75px;
}
@media only screen and (max-width: 320px) {
  .fs-15,
  .fs-sm {
    font-size: 12px !important;
  }
  .fs-3 {
    font-size: 14px !important;
  }
  .sm-no-padding {
    padding-left: 0px;
    padding-right: 0px;
  }
  .image-product {
    width: 70px;
    height: 70px;
  }
}
.message-bottom {
  background-color: #f5f5f5;
}
</style>
