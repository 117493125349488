<template>
  <ion-content>
    <span>
      <ion-grid
        class="ion-no-padding-start ion-no-padding-end"
        v-for="(item, key) in list?.data || []"
        :key="item.id"
      >
        <div class="ion-padding-start ion-padding-end no-padding" @click.stop="viewOrderDetail(item)">
          <ion-row style="margin-top: 20px">
            <ion-col size="12">
              <div class="fs-3 fw-bold text-center">
                {{ item.supplier_name }}
              </div>
            </ion-col>
          </ion-row>

          <ion-row class="mt-10" v-if="item.orderItems[0]?.customer?.name?.length < 25">
            <ion-col size="6">
              <ion-text class="medium fs-12px">{{ item.orderItems[0].customer.name }}</ion-text>
            </ion-col>
            <ion-col size="6" class="ion-text-end" v-if="info(item.user)">
              <div class="medium fs-12px d-flex justify-end">
                [{{ item.user.first_name }}&nbsp;{{ item.user.last_name }}] &nbsp;{{
                  formatTime(item.updated_at, 3)
                }}
              </div>
            </ion-col>
            <ion-col size="12" class="pt-0 pb-0 my-1">
              <ion-text class="medium fs-12px">{{ item.orderItems[0].customer.account_number }}</ion-text>
            </ion-col>
            <ion-col size="12" class="pt-0 pb-0" v-if="!info(item.user)">
              <div class="medium fs-12px d-flex">
                [{{ item.user.first_name }}&nbsp;{{ item.user.last_name }}] &nbsp;{{
                  formatTime(item.updated_at, 3)
                }}
              </div>
            </ion-col>
          </ion-row>
          <ion-row class="mt-10" v-else>
            <ion-col size="12" class="pt-0 pb-0">
              <ion-text class="medium fs-12px">{{ item.orderItems[0].customer.name }}</ion-text>
            </ion-col>
            <ion-col size="12" class="pt-0 pb-0 my-1">
              <ion-text class="medium fs-12px">{{ item.orderItems[0].customer.account_number }}</ion-text>
            </ion-col>
            <ion-col size="12" class="pt-0 pb-0">
              <div class="medium fs-12px d-flex">
                [{{ item.user.first_name }}&nbsp;{{ item.user.last_name }}] &nbsp;{{
                  formatTime(item.updated_at, 3)
                }}
              </div>
            </ion-col>
          </ion-row>

          <ion-row class="mt-10">
            <ion-col size="6">
              <ion-text class="text-bold fs-3">{{ $t('orderB2b.order') }} #{{ item.id }}</ion-text>
            </ion-col>
            <ion-col size="6" class="d-flex justify-end">
              <ion-text class="text-bold fs-3" align="right">
                {{ priceFormatter(currencySymbol, (item.total_price + item.total_tax).toFixed(2)) }}</ion-text
              >
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="pre-paid ion-no-padding" size="12">
              <ion-text
                class="un-paid"
                v-if="
                  !hasPendingPayment(item) &&
                  item.orderStatus.name == ORDER_STATUS_NAME.PROCESSING &&
                  !item.is_paid
                "
              >
                {{ $t('un_paid') }}
              </ion-text>
              <ion-text
                class="paid"
                v-if="
                  !hasPendingPayment(item) &&
                  item.orderStatus.name == ORDER_STATUS_NAME.PROCESSING &&
                  item.is_paid
                "
              >
                {{ $t('paid') }}
              </ion-text>
              <ion-text class="pending-payment" v-if="hasPendingPayment(item)">
                {{ $t('pending_payment') }}
              </ion-text>
            </ion-col>
          </ion-row>

          <ion-row class="align-center">
            <ion-col size="10">
              <ion-text class="medium fs-12px">
                <ion-label class="d-flex">
                  {{ $t('orderB2b.created') }}: {{ formatTime(item.created_at, 1) }}
                </ion-label>
                <ion-label class="d-flex">
                  {{ $t('orderB2b.delivery') }}: {{ formatTime(item.delivery_date, 2) }}
                  <span v-if="item.delivery_time && item.delivery_time !== 'null'"
                    >&nbsp;{{ item.delivery_time }}</span
                  >
                </ion-label>
              </ion-text>
            </ion-col>

            <ion-col size="2" class="d-flex justify-end">
              <ion-text class="medium fs-12px" align="right"
                >{{ item.orderItems.length }}
                {{ item.orderItems.length > 1 ? $t('orderB2b.items') : $t('orderB2b.item') }}</ion-text
              >
            </ion-col>
          </ion-row>

          <ion-row class="align-center">
            <ion-col size="6">
              <span class="process-item">
                <ion-button
                  class="status"
                  size="small"
                  :color="
                    item.orderStatus.name === ORDER_STATUS_NAME.CANCELLED
                      ? 'danger'
                      : item.orderStatus.name === ORDER_STATUS_NAME.DELIVERED
                      ? 'light'
                      : 'primary'
                  "
                >
                  <ion-icon slot="icon-only" :icon="changeIcon(item.orderStatus.name)"></ion-icon>
                  <span class="ml-1 small-device">{{ item.orderStatus.name }}</span>
                </ion-button>
              </span>
            </ion-col>

            <ion-col size="6" align="right">
              <ion-buttons class="justify-end">
                <!-- <ion-button
                  fill="outline"
                  class="border-outline"
                  @click.stop="openModalInvoice(item.id, $event)"
                  v-if="
                    [
                      'processed',
                      'updated',
                      'completed',
                      'collected',
                      'delivering',
                      'arrived',
                      'delivered'
                    ].includes(item.orderStatus.name)
                  "
                >
                  <ion-label class="pl-1 pr-1 fs-12 small-device">{{ $t('orderB2b.invoice') }} CHECK</ion-label>
                </ion-button> -->
                <ion-button
                  fill="outline"
                  class="border-outline text-capitalize ml-1"
                  @click.stop="repeatOrder(item.id, item.orderStatus.name)"
                  v-if="showRepeatButton(item)"
                >
                  <ion-label class="pl-1 pr-1 fs-12 small-device">{{ $t('orderB2b.repeat') }} </ion-label>
                </ion-button>

                <ion-button
                  fill="outline"
                  class="border-outline text-capitalize"
                  @click.stop="openEditModal(item)"
                  v-if="showEditButton(item)"
                >
                  <ion-label class="pl-1 pr-1 fs-12 small-device">{{ $t('edit') }} </ion-label>
                </ion-button>
                <ion-button
                  fill="outline"
                  class="border-outline text-capitalize"
                  @click.stop="goToPaymentDetailsPage(item)"
                  v-if="showPaymentInstructionButton(item)"
                >
                  <ion-label class="pl-1 pr-1 fs-12 small-device"
                    >{{ $t('orderB2b.paymentInstruction') }}
                  </ion-label>
                </ion-button>
              </ion-buttons>
            </ion-col>
          </ion-row>
          <ion-button
            @click.stop="
              item.credit_note > 0
                ? setOpenInvoiceAndCreditNote(true, item.orderItems[0].invoice_id, item.id)
                : setOpenInvoiceDetail(true, item.orderItems[0].invoice_id, item.id)
            "
            v-if="
              [
                ORDER_STATUS_NAME.PROCESSED,
                ORDER_STATUS_NAME.UPDATED,
                ORDER_STATUS_NAME.COMPLETED,
                ORDER_STATUS_NAME.COLLECTED,
                ORDER_STATUS_NAME.DELIVERING,
                ORDER_STATUS_NAME.ARRIVED,
                ORDER_STATUS_NAME.DELIVERED
              ].includes(item.orderStatus.name) && item.orderItems[0]?.invoice_id
            "
            class="invoice-btn text-capitalize mt-2"
            color="primary"
            expand="block"
            >{{ item.credit_note > 0 ? $t('invoice_cn.invoice_cn') : $t('invoice_cn.invoice') }}</ion-button
          >
          <ion-row v-if="getCancelledBy(key)">
            <ion-col size="12">
              <div class="message-bottom px-2 py-2 fs-13 fw-600">
                <ion-text>
                  <div>Cancelled by {{ getCancelledBy(key) }}</div>
                </ion-text>
              </div>
            </ion-col>
          </ion-row>
        </div>
        <ion-item-divider class="d-flex mb-10 mt-10" style="min-height: 1px" />
      </ion-grid>

      <ion-grid style="height: 100%" v-if="list?.data.length === 0">
        <ion-row class="justify-center align-center" style="height: 100%">
          <div class="text-center">
            <ion-img
              style="width: 110px; height: 110px; margin: 0 auto"
              src="/assets/images/empty-box.png"
            ></ion-img>
            <div class="fs-4 mt-4 mb-2 fw-bold">{{ $t('sorry') }}</div>
            <div class="fs-3">{{ $t('no_transaction_made') }}</div>
            <ion-button class="mt-3 text-capitalize" color="primary" @click="goHome()">
              <span class="ml-1">{{ $t('start_purchasing_today') }}</span>
            </ion-button>
          </div>
        </ion-row>
      </ion-grid>
    </span>

    <ion-infinite-scroll
      @ionInfinite="loadMore($event)"
      threshold="100px"
      id="infinite-scroll"
      :disabled="isDisabled"
    >
      <ion-infinite-scroll-content loading-spinner="bubbles" loading-text="Loading ...">
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </ion-content>

  <ion-modal
    ref="modal"
    :initial-breakpoint="1"
    :breakpoints="[0, 0.25, 0.5, 0.75, 1]"
    mode="ios"
    css-class="default-bottom-sheet-modal"
    :is-open="isOpenRef"
    @didDismiss="setOpen(false)"
  >
    <update-order :orderEditDetail="orderEditDetail" @update-data="updateData"></update-order>
  </ion-modal>
  <ion-modal
    mode="md"
    :backdropDismiss="true"
    :is-open="isOpenInvoice"
    @didDismiss="setOpenInvoiceDetail(false, null)"
  >
    <invoice-detail
      :currencySymbol="currencySymbol"
      :detailOrderId="orderDetailId"
      :invoiceId="invoiceId"
      @openPaymentHistory="onEnterPaymentHistory"
      @close-page="setOpenInvoiceDetail(false, null)"
    />
  </ion-modal>
  <ion-modal
    mode="md"
    :backdropDismiss="true"
    :is-open="isOpenInvoiceCreditNotes"
    @didDismiss="setOpenInvoiceAndCreditNote(false, null, null)"
  >
    <invoice-and-credit-note
      :detailOrderId="orderDetailId"
      :invoiceId="invoiceId"
      :currencySymbol="currencySymbol"
      @close-page="setOpenInvoiceAndCreditNote(false, null, null)"
    />
  </ion-modal>
  <ion-modal mode="ios" :is-open="isOpenPaymentHistory" @didDismiss="setOpenPaymentHistory(false)">
    <payment-history
      @closeModal="setOpenPaymentHistory(false)"
      :data="paymentHistories"
      :currencySymbol="currencySymbol"
    />
  </ion-modal>
  <ion-loading
    :is-open="isOpenLoadingref"
    @didDismiss="setOpenLoading(false)"
    cssClass="custom-loading"
    message=""
    spinner="crescent"
  >
  </ion-loading>
</template>

<script>
import { apolloClient } from '@/main';
import { getInvoiceById, getPurchaseB2bOrders } from '@/modules/b2b/services/graphql';
import { ACTIONS } from '@/modules/b2b/store/orders/purchase/actions';
import { PaymentHistory } from '@/modules/shared/components/invoices-payment';
import { ACTION_BY_MERCHANT, MERCHANT_APP_NAME, ORDER_STATUS_NAME } from '@/modules/shared/constants';
import { useLoading } from '@/modules/shared/utils/';
import clevertap from '@/services/shared/helper/clevertap';
import { priceFormatter } from '@/utils/';
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItemDivider,
  IonLabel,
  IonLoading,
  IonModal,
  IonRow,
  IonText,
  alertController,
  modalController,
  popoverController
} from '@ionic/vue';
import dayjs from 'dayjs';
import {
  carSportOutline,
  checkmarkCircleOutline,
  checkmarkDoneOutline,
  closeOutline,
  createOutline,
  flashOutline,
  navigateCircleOutline,
  repeatOutline
} from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { createNamespacedHelpers } from 'vuex';
import InvoiceDetail from './Invoice';
import InvoiceAndCreditNote from './InvoiceAndCreditNoteList';
import OrderDetail from './OrderDetail';
import UpdateOrder from './UpdateOrder';

const { mapActions, mapGetters } = createNamespacedHelpers('b2b/purchase');
export default defineComponent({
  name: 'purchase-item',
  components: {
    IonItemDivider,
    IonText,
    IonGrid,
    IonRow,
    IonCol,
    IonLabel,
    IonIcon,
    IonButtons,
    IonButton,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonContent,
    IonImg,
    IonModal,
    UpdateOrder,
    InvoiceDetail,
    InvoiceAndCreditNote,
    IonLoading,
    PaymentHistory
  },
  setup() {
    const user = ref(null);
    const selectCompany = ref(null);
    const NoLoadItems = ref(false);
    const isOpenRef = ref(false);
    const setOpen = (state) => (isOpenRef.value = state);
    // loading
    const isOpenLoadingref = ref(false);
    const setOpenLoading = (state) => (isOpenLoadingref.value = state);
    const { showLoading } = useLoading();
    const isOpenRefPopover = ref(false);
    const event = ref();
    const setOpenPopover = (state, ev) => {
      event.value = ev;
      isOpenRefPopover.value = state;
    };
    const isOpenPaymentHistory = ref(false);
    const setOpenPaymentHistory = (state) => (isOpenPaymentHistory.value = state);
    return {
      repeatOutline,
      closeOutline,
      createOutline,
      checkmarkCircleOutline,
      flashOutline,
      carSportOutline,
      navigateCircleOutline,
      checkmarkDoneOutline,
      user,
      selectCompany,
      NoLoadItems,
      isOpenRef,
      setOpen,
      setOpenLoading,
      isOpenLoadingref,
      showLoading,
      popoverController,
      isOpenRefPopover,
      event,
      setOpenPopover,
      priceFormatter,
      isDisabled: ref(false),
      orderEditDetail: ref(null),
      isReload: ref(false),
      customerId: ref(''),
      idInvoice: ref(null),
      isOpenInvoice: ref(false),
      isOpenInvoiceCreditNotes: ref(false),
      invoiceAndCreditNoteData: ref({}),
      invoiceId: ref(null),
      orderDetailId: ref(null),
      isOpenPaymentHistory,
      setOpenPaymentHistory,
      paymentHistories: ref({}),
      ORDER_STATUS_NAME
    };
  },
  computed: {
    ...mapGetters(['list', 'totalCount', 'params', 'error', 'status', 'loading', 'invoiceList']),
    currencySymbol() {
      return this.user ? this.user.country.currency_symbol : '';
    },
    formatDateTime() {
      return (value) => {
        return value ? new Date(value).toLocaleString() : null;
      };
    },
    selectCompanyId() {
      return this.selectCompany ? this.selectCompany.id : null;
    },
    info() {
      return (value) => {
        let str1 = value?.first_name?.replace(/\s/g, '');
        let str2 = value?.last_name?.replace(/\s/g, '');
        if (str1?.length + str2?.length < 10) {
          return true;
        } else {
          return false;
        }
      };
    },
    userClassId() {
      return this.user ? this.user.user_type_id : null;
    },
    changeIcon() {
      return (value) => {
        if (value.includes(ORDER_STATUS_NAME.PROCESSING)) return repeatOutline;
        else if (value.includes(ORDER_STATUS_NAME.PROCESSED)) return repeatOutline;
        else if (value.includes(ORDER_STATUS_NAME.UPDATED)) return createOutline;
        else if (value.includes(ORDER_STATUS_NAME.COMPLETED)) return checkmarkCircleOutline;
        else if (value.includes(ORDER_STATUS_NAME.COLLECTED)) return flashOutline;
        else if (value.includes(ORDER_STATUS_NAME.DELIVERING)) return carSportOutline;
        else if (value.includes(ORDER_STATUS_NAME.ARRIVED)) return navigateCircleOutline;
        else if (value.includes(ORDER_STATUS_NAME.DELIVERED)) return checkmarkDoneOutline;
        else return closeOutline;
      };
    },
    getCancelledBy() {
      return (key) => {
        const item = this.list.data[key];
        const status = item?.orderStatus.name;
        let cancelledBy = '';

        if (status === ORDER_STATUS_NAME.CANCELLED && item.app_name !== MERCHANT_APP_NAME) {
          cancelledBy = 'Supplier';
        } else if (
          status === ORDER_STATUS_NAME.CANCELLED &&
          item.app_name === MERCHANT_APP_NAME &&
          item?.orderLogs?.[0]?.description?.logs?.[0]?.reason === ACTION_BY_MERCHANT
        ) {
          cancelledBy = item.app_name;
        }
        return cancelledBy;
      };
    },
    hasPendingPayment() {
      return (item) =>
        item.paymentTransactionItems &&
        item.paymentTransactionItems.length > 0 &&
        item.paymentTransactionItems[0].paymentTransaction.status === 'pending';
    },
    showEditButton() {
      return (item) =>
        this.userClassId != 12 &&
        item.orderStatus.name === ORDER_STATUS_NAME.PROCESSING &&
        item.order_status_id !== 2 &&
        !this.hasPendingPayment(item);
    },
    showRepeatButton() {
      return (item) => item.orderStatus.name !== ORDER_STATUS_NAME.PROCESSING;
    },
    showPaymentInstructionButton() {
      return (item) =>
        item.paymentTransactionItems &&
        item.paymentTransactionItems.length > 0 &&
        this.user?.id === item.paymentTransactionItems[0].paymentTransaction.created_by &&
        this.hasPendingPayment(item);
    }
  },
  inject: ['$storage'],
  async mounted() {
    this.user = await this.$storage.getUser();
    this.selectCompany = await this.$storage.getSelectedCompany();
  },
  methods: {
    ...mapActions([
      ACTIONS.GET_INVOICE_BY_ID,
      ACTIONS.GET_LIST_INVOICE,
      ACTIONS.RELOAD_PURCHASE,
      ACTIONS.GET_PURCHASE,
      ACTIONS.LOAD_MORE_PURCHASE,
      ACTIONS.GET_CUSTOMER_PEPPOL,
      ACTIONS.GET_TENANT_INTEGRATION_STORECOVE,
      ACTIONS.REPEAT_ORDER_B2B,
      ACTIONS.GET_VIEWS_PRICES,
      ACTIONS.GET_TOTAL_COUNT_PURCHASE_B2B_ORDER
    ]),
    async handleGetData() {
      const promiseAll = [this.getData(), this.handleGetTotalCountOrder()];
      await Promise.all(promiseAll);

      // need to check if we have
      if (this.$route.query?.order_id) {
        const orderId = this.$route.query?.order_id;
        const orderIds = this.list?.data.map((obj) => obj.id);
        // find order from exiting data
        if (orderIds.indexOf(orderId)) {
          const item = this.list?.data.find((obj) => obj.id == orderId);
          await this.openModalDetail(item);
        } else {
          // fetch order item info
          const { res } = await apolloClient.mutate({
            mutation: getPurchaseB2bOrders,
            variables: {
              orderId: parseInt(orderId)
            }
          });

          // show order details modal
          if (res && res.getPurchaseB2bOrders) {
            await this.openModalDetail(res.getPurchaseB2bOrders.data[0]);
          }
        }
      }
    },
    handleGetTotalCountOrder() {
      this[ACTIONS.GET_TOTAL_COUNT_PURCHASE_B2B_ORDER]({
        params: {
          offset: 0,
          isIncludeTotalCount: true
        }
      });
    },
    formatTime(params, id) {
      return id === 1
        ? dayjs(params).format('D MMM YYYY H:mm')
        : id === 2
        ? dayjs(params).format('D MMM YYYY ')
        : dayjs(params).format('YYYY-MM-DD HH:mm:ss');
    },
    async getData() {
      // disable loadmore action
      this.isDisabled = true;
      await this[ACTIONS.GET_PURCHASE]({
        params: {
          offset: 0,
          isIncludeTotalCount: true
        }
      });
      // enable loadmore action
      this.isDisabled = false;
    },
    loadMore(e) {
      try {
        if (this.NoLoadItems) return;

        this.NoLoadItems = true;
        // check continue status
        if (this.list.data.length === this.totalCount) {
          this.isDisabled = true;
          return;
        }
        this[ACTIONS.LOAD_MORE_PURCHASE]({
          params: {
            isIncludeTotalCount: false,
            offset: this.list.data.length
          },
          onFinish: () => {
            e.target.complete();
          }
        });
      } catch (error) {
        this.NoLoadItems = true;
      } finally {
        this.NoLoadItems = false;
      }
    },
    viewOrderDetail(item) {
      this.$router.push({ path: `/b2b/order/purchase/order-detail/${item.id}` });
    },
    async openModalDetail(item) {
      const modal = await modalController.create({
        component: OrderDetail,
        cssClass: 'order-detail',
        componentProps: {
          item: item,
          closeDetail: async () => {
            await modal.dismiss();
          }
        }
      });
      return modal.present();
    },
    async repeatOrder(id, orderStatus) {
      const customerDefault = await this.$storage.getSelectedCompany();
      this.customerId = customerDefault.id;
      await this[ACTIONS.REPEAT_ORDER_B2B]({
        customerId: this.customerId,
        orderId: id
      });
      if (this.error) {
        const alert = await alertController.create({
          message: this.error.message,
          buttons: [
            {
              text: 'CLOSE'
            }
          ]
        });
        await alert.present();
      } else {
        this.$router.push('/b2b/cart');
      }
      await clevertap.onUserRepeatOrder(orderStatus);
    },
    onEnterPaymentHistory(paymentHistories) {
      this.paymentHistories = paymentHistories;
      this.setOpenPaymentHistory(true);
    },
    async openModalInvoice(order_id, $event) {
      this.idInvoice = order_id;
      this.setOpenPopover(true, $event);
    },
    async checkInvoiceAndCreditNotes(invoice_id) {
      if (invoice_id != null) {
        const { data } = await apolloClient.query({
          query: getInvoiceById,
          variables: {
            invoiceId: invoice_id
          }
        });
        this.invoiceAndCreditNoteData = data.getInvoiceById;
      }
    },
    async openEditModal(item) {
      this.setOpenLoading(true);

      await this[ACTIONS.GET_VIEWS_PRICES]({
        orderId: item.id
      });
      this.orderEditDetail = item;

      this.setOpenLoading(false);

      this.orderEditDetail = item;
      this.setOpen(true);
    },
    async updateData() {
      this.setOpen(false);
      await this[ACTIONS.GET_PURCHASE]({
        params: {
          offset: 0
        }
      });
    },
    goHome() {
      this.$router.replace({ name: 'home', params: { cate: 'all' } });
    },
    goToPaymentDetailsPage(item) {
      this.$router.push(`/b2b/payment-transaction/${item.id}`);
    },
    setOpenInvoiceDetail(val, invoice_id, orderId) {
      this.orderDetailId = orderId;
      this.invoiceId = invoice_id;
      this.isOpenInvoice = val;
    },
    setOpenInvoiceAndCreditNote(val, invoice_id, orderId) {
      this.orderDetailId = orderId;
      this.invoiceId = invoice_id;
      this.isOpenInvoiceCreditNotes = val;
    }
  }
});
</script>
<style src="./style.scss" lang="scss" scoped></style>
