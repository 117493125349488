<template>
  <ion-page v-if="product">
    <ion-header class="ion-no-border">
      <ion-toolbar>
        <div class="pl-3 pr-3 pt-3">
          <div class="text-danger fs-3">
            {{ product.dispute_status != null ? $t('view_dispute_status') : $t('report_your_problem') }}
          </div>
          <div class="d-flex justify-space-between align-center">
            <div>
              <div class="mt-2 mb-2">{{ $t('orderB2b.order') }} #{{ product.order_id }}</div>
              <div class="mt-1 grey5-color">
                <ion-text class="ion-no-padding">{{
                  dayjs(item.delivery_date).format('DD MMM YYYY')
                }}</ion-text>
              </div>
            </div>
            <div v-if="product.dispute_status != null">
              <ion-chip color="primary disable-click">
                <ion-icon :icon="repeatOutline"></ion-icon>
                <ion-label>{{ getDisputeStatus(product.dispute_status) }}</ion-label>
              </ion-chip>
            </div>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>
    <ion-content v-if="product.dispute_status != null">
      <ion-grid class="ion-padding ion-no-padding-top">
        <ion-row>
          <ion-col>
            <div class="title fs-15px" style="color: black; line-height: 22px; max-width: 80%">
              {{ product.sku_name }}
            </div>
            <!-- <div class="mb-2">Weights:</div> -->
            <div class="d-flex mt-2 mb-1 fs-3 justify-space-between">
              <div v-if="product.catch_weight" class="medium text-italic" style="font-size: 12px">
                <span class="text-weight-bold">{{ $t('Weights') }}:</span>
                {{ product.catch_weight }} {{ $t('kg') }}
              </div>
              <div class="fw-700" style="color: #757575">
                {{ priceFormatter(currencySymbol, displayPrice(product.sale_unit_price)) }}
              </div>
              <div>x{{ product.total_qty }}</div>
              <div class="fw-700 text-primary">
                {{ priceFormatter(currencySymbol, displayPrice(product.total_price)) }}
              </div>
            </div>
          </ion-col>
          <ion-item-divider style="min-height: 1px" class="mt-3 mb-3" />
        </ion-row>

        <div class="mb-2">{{ $t('orderB2b.selectProblem') }}</div>

        <ion-list class="reason" lines="none">
          <ion-radio-group :value="product.dispute_reason.toString()">
            <ion-row style="width: 100%">
              <ion-col size="4">
                <ion-item>
                  <ion-label>{{ REASONS.GOODS_SPOIL.value }}</ion-label>
                  <ion-radio
                    v-if="product.dispute_reason === REASONS.GOODS_SPOIL.id"
                    :value="REASONS.GOODS_SPOIL.id.toString()"
                  ></ion-radio>
                </ion-item>
              </ion-col>
              <ion-col size="4">
                <ion-item>
                  <ion-label>{{ REASONS.MISSING_ITEM.value }}</ion-label>
                  <ion-radio
                    v-if="product.dispute_reason === REASONS.MISSING_ITEM.id"
                    :value="REASONS.MISSING_ITEM.id.toString()"
                  ></ion-radio>
                </ion-item>
              </ion-col>
              <ion-col size="4">
                <ion-item>
                  <ion-label>{{ REASONS.DELAYED_DELIVERY.value }}</ion-label>
                  <ion-radio
                    v-if="product.dispute_reason === REASONS.DELAYED_DELIVERY.id"
                    :value="REASONS.DELAYED_DELIVERY.id.toString()"
                  ></ion-radio>
                </ion-item>
              </ion-col>
            </ion-row>
          </ion-radio-group>
        </ion-list>
        <ion-item-divider style="min-height: 1px" class="mt-3 mb-3" />

        <div class="mb-2">{{ $t('orderB2b.selectRemedy') }}</div>

        <ion-list class="remedy ion-padding" lines="none">
          <ion-radio-group :value="product.dispute_remedy.toString()">
            <ion-item class="mb-2">
              <ion-label>{{ REMEDIES.OTO_EX.value }}</ion-label>
              <ion-radio
                v-if="product.dispute_remedy === REMEDIES.OTO_EX.id"
                :value="REMEDIES.OTO_EX.id.toString()"
              ></ion-radio>
            </ion-item>
            <ion-item class="mb-2">
              <ion-label>{{ REMEDIES.CREDIT.value }}</ion-label>
              <ion-radio
                v-if="product.dispute_remedy === REMEDIES.CREDIT.id"
                :value="REMEDIES.CREDIT.id.toString()"
              ></ion-radio>
            </ion-item>
            <ion-item class="mb-2">
              <ion-label>{{ REMEDIES.PIC_ITM.value }}</ion-label>
              <ion-radio
                v-if="product.dispute_remedy === REMEDIES.PIC_ITM.id"
                :value="REMEDIES.PIC_ITM.id.toString()"
              ></ion-radio>
            </ion-item>
            <ion-item>
              <ion-label>{{ REMEDIES.NON.value }}</ion-label>
              <ion-radio
                v-if="product.dispute_remedy === REMEDIES.NON.id"
                :value="REMEDIES.NON.id.toString()"
              ></ion-radio>
            </ion-item>
          </ion-radio-group>
        </ion-list>
        <ion-item-divider style="min-height: 1px" class="mt-3 mb-3" />

        <ion-row>
          <ion-col>
            <ion-button expand="block" fill="outline" @click="closeModal()" class="text-uppercase">{{
              $t('close')
            }}</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>

    <ion-content v-else>
      <div class="ion-padding">
        <ion-row>
          <ion-col>
            <div class="title fs-15px" style="color: black; line-height: 22px">
              {{ product.sku_name }}
            </div>
            <!-- <div class="mb-2">Weights:</div> -->
            <div class="d-flex mt-2 mb-1 fs-3 justify-space-between">
              <div v-if="product.catch_weight" class="medium text-italic" style="font-size: 12px">
                <span class="text-weight-bold">{{ $t('Weights') }}:</span>
                {{ product.catch_weight }} {{ $t('kg') }}
              </div>
              <div class="fw-700" style="color: #757575">$ {{ displayPrice(product.sale_unit_price) }}</div>
              <div v-if="!product.is_order_by_weight">x{{ product.total_qty }}</div>
              <div v-else>{{ product.total_qty }} KG</div>
              <div class="fw-700 text-primary">$ {{ displayPrice(product.total_price) }}</div>
            </div>
          </ion-col>
          <ion-item-divider style="min-height: 1px" class="mt-3 mb-3" />
        </ion-row>

        <div class="mb-2">{{ $t('orderB2b.selectProblem') }}</div>

        <ion-list class="reason" lines="none">
          <ion-radio-group class="d-flex" v-model="selectedReasonId">
            <ion-row style="width: 100%">
              <ion-col size="4">
                <ion-item>
                  <ion-label>{{ REASONS.GOODS_SPOIL.value }}</ion-label>
                  <ion-radio :value="REASONS.GOODS_SPOIL.id"></ion-radio>
                </ion-item>
              </ion-col>
              <ion-col size="4">
                <ion-item>
                  <ion-label>{{ REASONS.MISSING_ITEM.value }}</ion-label>
                  <ion-radio :value="REASONS.MISSING_ITEM.id"></ion-radio>
                </ion-item>
              </ion-col>
              <ion-col size="4">
                <ion-item>
                  <ion-label>{{ REASONS.DELAYED_DELIVERY.value }}</ion-label>
                  <ion-radio :value="REASONS.DELAYED_DELIVERY.id"></ion-radio>
                </ion-item>
              </ion-col>
            </ion-row>
          </ion-radio-group>
        </ion-list>
        <ion-item-divider style="min-height: 1px" class="mt-3 mb-3" />

        <div class="mb-2">{{ $t('orderB2b.selectRemedy') }}</div>

        <ion-list class="remedy ion-padding" lines="none">
          <ion-radio-group v-model="selectedRemedyId">
            <ion-item class="mb-2">
              <ion-label>{{ REMEDIES.OTO_EX.value }}</ion-label>
              <ion-radio :value="REMEDIES.OTO_EX.id"></ion-radio>
            </ion-item>
            <ion-item class="mb-2">
              <ion-label>{{ REMEDIES.CREDIT.value }}</ion-label>
              <ion-radio :value="REMEDIES.CREDIT.id"></ion-radio>
            </ion-item>
            <ion-item class="mb-2">
              <ion-label>{{ REMEDIES.PIC_ITM.value }}</ion-label>
              <ion-radio :value="REMEDIES.PIC_ITM.id"></ion-radio>
            </ion-item>
            <ion-item>
              <ion-label>{{ REMEDIES.NON.value }}</ion-label>
              <ion-radio :value="REMEDIES.NON.id"></ion-radio>
            </ion-item>
          </ion-radio-group>
        </ion-list>
        <ion-item-divider style="min-height: 1px" class="mt-3 mb-3" />

        <ion-row>
          <ion-col>
            <ion-button expand="block" fill="outline" @click="closeModal()" class="text-uppercase">{{
              $t('close')
            }}</ion-button>
          </ion-col>
          <ion-col>
            <ion-button
              expand="block"
              v-if="!item.dispute_status"
              color="primary"
              class="text-uppercase"
              @click="submitReport"
              >{{ $t('submit') }}</ion-button
            >
          </ion-col>
        </ion-row>
      </div>
    </ion-content>

    <ion-loading
      :is-open="isOpenLoadingref"
      @didDismiss="setOpenLoading(false)"
      cssClass="custom-loading"
      message=""
      spinner="crescent"
    >
    </ion-loading>
  </ion-page>
</template>

<script>
import { displayPrice } from '@/modules/b2b/services/libs/helper';
import { ACTIONS } from '@/modules/b2b/store/orders/purchase/actions';
import { priceFormatter } from '@/utils/';
import {
  IonButton,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonToolbar,
  alertController
} from '@ionic/vue';
import dayjs from 'dayjs';
import { repeatOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters } = createNamespacedHelpers('b2b/purchase');
const DISPUTE_STATUS = ['Processing', 'Resolved', 'Declined'];

export default defineComponent({
  name: 'RepeatOrder',
  components: {
    IonCol,
    IonPage,
    IonRow,
    IonContent,
    IonButton,
    IonIcon,
    IonItemDivider,
    IonChip,
    IonLabel,
    IonHeader,
    IonToolbar,
    IonList,
    IonRadioGroup,
    IonItem,
    IonRadio,
    IonGrid,
    IonLoading
  },
  setup() {
    const user = ref(null);
    // loading
    const isOpenLoadingref = ref(false);
    const setOpenLoading = (state) => (isOpenLoadingref.value = state);

    return {
      user,
      repeatOutline,
      setOpenLoading,
      isOpenLoadingref,
      displayPrice,
      priceFormatter,
      dayjs
    };
  },
  data() {
    return {
      selectedReasonId: null,
      selectedRemedyId: null,
      REASONS: {
        GOODS_SPOIL: {
          id: 0,
          value: this.$t('orderB2b.goodsSpoild')
        },
        MISSING_ITEM: {
          id: 1,
          value: this.$t('orderB2b.missingItem')
        },
        DELAYED_DELIVERY: {
          id: 2,
          value: this.$t('orderB2b.deplayDelivery')
        }
      },
      REMEDIES: {
        OTO_EX: {
          id: 0,
          value: this.$t('orderB2b.requestingExchange')
        },
        CREDIT: {
          id: 1,
          value: this.$t('orderB2b.requestingCreditNote')
        },
        PIC_ITM: {
          id: 2,
          value: this.$t('orderB2b.requestingItem')
        },
        NON: {
          id: 3,
          value: this.$t('orderB2b.acceptingRemedy')
        }
      },
      DISPUTE_STATUS: [
        this.$t('dispute_status_processing'),
        this.$t('dispute_status_resolved'),
        this.$t('dispute_status_declined')
      ]
    };
  },
  props: {
    product: {
      type: Object,
      default: null
    },
    item: {
      type: Object,
      default: null
    }
  },
  emits: ['close-modal', 'update-status'],
  computed: {
    ...mapGetters(['itemDisputes']),
    formatDateTime() {
      return (value) => {
        return value ? new Date(value).toLocaleString() : null;
      };
    },
    currencySymbol() {
      return this.user ? this.user.country.currency_symbol : '';
    }
  },
  inject: ['$storage'],
  async mounted() {
    this.user = await this.$storage.getUser();
  },

  methods: {
    ...mapActions([ACTIONS.ORDER_ITEM_DISPUTES, ACTIONS.GET_PURCHASE_DETAIL, ACTIONS.GET_PURCHASE_DETAIL]),
    async submitReport() {
      let payload = {
        orderItemId: this.product.id,
        reason: parseInt(this.selectedReasonId),
        remedy: parseInt(this.selectedRemedyId),
        status: 0,
        lastUserId: this.product.user_id
      };

      this.setOpenLoading(true);
      await this[ACTIONS.ORDER_ITEM_DISPUTES]({
        orderItemDisputesPayload: payload
      });
      this.setOpenLoading(false);
      this[ACTIONS.GET_PURCHASE_DETAIL]({ orderId: this.item.id });

      this.$emit('update-status', payload);

      const alert = await alertController.create({
        header: 'Submitted',
        message: this.$t('submit_report_mess'),
        buttons: [
          {
            text: this.$t('close'),
            role: 'cancel'
          }
        ]
      });
      await alert.present();
    },
    getDisputeStatus(disputeStatus) {
      let item = JSON.parse(JSON.stringify(this.product));

      if (item.dispute_status != null && disputeStatus < DISPUTE_STATUS.length) {
        return DISPUTE_STATUS[disputeStatus];
      } else {
        return this.$t('dispute_status_unknown');
      }
    },
    closeModal() {
      this.$emit('close-modal');
    }
  }
});
</script>

<style lang="scss" scoped>
// ion-content::part(scroll) {
//   overflow: hidden;
// }
ion-list {
  &.reason {
    ion-item {
      border: 1px solid #9e9e9e;
      width: 100%;
      border-radius: 5px;
      position: relative;
    }
    ion-label {
      font-size: 12px !important;
      white-space: normal !important;
      text-align: center;
      line-height: 21px;
      padding: 0px 8px 0px 24px;
      margin-top: 4px;
      margin-bottom: 4px;
    }
    ion-radio-group .item-radio-checked {
      // background-color: #64ae0f !important;
      color: #64ae0f !important;
      border: 1px solid #64ae0f !important;
    }
  }
  &.remedy {
    ion-item {
      border: 1px solid #9e9e9e;
      width: 100%;
      border-radius: 5px;
      position: relative;
    }
    ion-label {
      font-size: 12px !important;
      white-space: normal !important;
      text-align: center;
      line-height: 21px;
      padding: 0px 8px 0px 8px;
      margin-top: 4px;
      margin-bottom: 4px;
    }
    ion-radio-group .item-radio-checked {
      // background-color: #64ae0f !important;
      color: #26a69a !important;
      border: 1px solid #26a69a !important;
    }
  }
}

ion-radio {
  --color: transparent;
  --color-checked: transparent;
  position: absolute;
  width: 100%;
  left: -10px;
  margin: 0px;
  height: 100%;
}
.grey5-color {
  color: var(--ion-color-grey5);
}
.text-danger {
  color: var(--ion-color-danger);
}

@media only screen and (max-width: 320px) {
  ion-list {
    &.reason {
      ion-label {
        font-size: 10px !important;
        padding: 0px 0px 0px 16px;
      }
    }
  }
}
</style>
