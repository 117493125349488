<template>
  <ion-page v-if="customerAndStatus">
    <ion-content :fullscreen="true" id="hidden-scroll">
      <ion-header translucent>
        <ion-toolbar>
          <ion-row class="py-2">
            <ion-col class="fs-3" style="font-size: 16px; font-weight: 500">
              {{ $t('orderB2b.filter') }}
            </ion-col>
            <ion-col class="ion-text-right primary fs-2 fw-700 text-uppercase" @click="resetFilter">
              {{ $t('orderB2b.reset') }}
            </ion-col>
          </ion-row>
        </ion-toolbar>
      </ion-header>

      <form>
        <ion-list class="pt-3 pb-3 pl-3">
          <ion-item id="open-branch-filter" class="ion-no-padding" @click="setOpenModal">
            <ion-label>{{ $t('orderB2b.companies') }} </ion-label>
            <ion-label id="companyName"
              ><span>{{ resultCompanyName }}</span></ion-label
            >
            <ion-select mode="ios" id="disable-click"> </ion-select>
          </ion-item>
          <ion-item class="ion-no-padding">
            <ion-label>{{ $t('orderB2b.status') }} </ion-label>
            <ion-select
              mode="ios"
              multiple="true"
              :cancelText="$t('cancel')"
              :okText="$t('OK')"
              :value="statusIds"
              @ionChange="statusIds = $event.target.value"
            >
              <ion-select-option
                :value="status.id"
                v-for="status in customerAndStatus.orderStatues"
                :key="status.id"
                >{{ status.name }}</ion-select-option
              >
            </ion-select>
          </ion-item>

          <ion-item
            class="ion-no-padding"
            :button="true"
            @click="handleOpenModalDateTime(startDeliveryDate, 'from')"
          >
            <ion-label>{{ $t('orderB2b.from') }}</ion-label>
            {{ formatDate(startDeliveryDate) }}
          </ion-item>

          <ion-item
            :button="true"
            @click="handleOpenModalDateTime(endDeliveryDate, 'to')"
            :class="isActive ? 'item-danger ion-no-padding' : 'ion-no-padding'"
          >
            <ion-label :class="isActive ? 'item-danger' : ''">{{ $t('orderB2b.to') }}</ion-label>
            {{ formatDate(endDeliveryDate) }}
          </ion-item>
          <ion-text color="danger" v-if="isActive">{{ $t('orderB2b.selectDate') }}</ion-text>
        </ion-list>

        <ion-row>
          <ion-col>
            <ion-button style="color: #9e9e9e" color="#000" expand="block" fill="outline" @click="closeModal">
              {{ $t('cancel') }}
            </ion-button>
          </ion-col>
          <ion-col>
            <ion-button :disabled="isActive" expand="block" @click="applyFilter">
              {{ $t('apply') }}
            </ion-button>
          </ion-col>
        </ion-row>
      </form>
    </ion-content>
    <ion-modal
      ref="modal"
      trigger="open-branch-filter"
      :initial-breakpoint="1"
      :breakpoints="[0, 0.25, 0.5, 0.75, 1]"
      mode="ios"
      css-class="default-bottom-sheet-modal"
      :is-open="isOpenRef"
      @didDismiss="closeCompaniesFilter"
      ><ion-page>
        <ion-header translucent class="ion-no-border pa-2">
          <ion-toolbar>
            <ion-text slot="start" class="fs-3 fw-500">{{ $t('companies') }}</ion-text>
            <ion-icon class="close-modal" slot="end" :icon="closeOutline" @click="closeModal"></ion-icon>
          </ion-toolbar>
        </ion-header>

        <div>
          <ion-grid>
            <ion-row class="ion-align-items-start px-2">
              <ion-col
                size="12"
                class="search-company"
                :class="isStartSearch ? 'new-search' : 'new-search-off'"
              >
                <ion-searchbar
                  mode="ios"
                  ref="searchInput"
                  @ionChange="updateSearchResults"
                  @ionFocus="setSearch(true)"
                  @ionBlur="setSearch(false)"
                  @keyup.enter="handleEnter"
                  :debounce="1200"
                  show-clear-button="never"
                  :disabled="isOpenLoadingref || (!showContent && !isOpenAlert)"
                  class="search"
                  :placeholder="$t('search_company')"
                >
                </ion-searchbar>
                <ion-icon
                  v-show="search.length > 0"
                  class="clear-search"
                  :icon="closeCircle"
                  @click="clearSearch"
                ></ion-icon>
              </ion-col>
              <ion-col v-if="showContent" size="12" class="ion-no-padding">
                <ion-item class="ion-no-padding mt-3 pb-2" lines="full">
                  <ion-checkbox
                    @ionChange="selectAllCompanies"
                    v-model="selectAll"
                    color="primary"
                    slot="start"
                    :checked="selectAll"
                  ></ion-checkbox>
                  <ion-text class="fs-3 fw-500">{{ $t('select_all') }}</ion-text>
                  <div
                    slot="end"
                    class="selected-count"
                    v-html="$t('selected_count', { selected_count: tempCustomerIds.length })"
                  ></div>
                </ion-item>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>

        <ion-content
          ref="companiesList"
          :scroll-y="onSearchCompanies ? false : true"
          :scroll-events="true"
          @ionScroll="handleScroll($event)"
          id="content-companies"
        >
          <div v-if="(!showContent && !isOpenAlert) || onSearchCompanies">
            <ion-item class="ion-no-padding" lines="none" v-for="(item, index) in 3" :key="index">
              <ion-label>
                <ion-skeleton-text :animated="true" style="width: 80%; height: 22px"> </ion-skeleton-text>
                <ion-skeleton-text class="mt-2" :animated="true" style="width: 40%; height: 22px">
                </ion-skeleton-text>
              </ion-label>
            </ion-item>
          </div>
          <div v-if="showContent && !onSearchCompanies">
            <ion-item
              class="ion-no-padding item-company adjust-right-2"
              lines="full"
              v-for="customer in listShowCustomerSearch.length > 0
                ? listShowCustomerSearch
                : listShowCustomer"
              :key="customer.id"
              @click="handleAddCustomerId(customer.id, customer.name)"
            >
              <ion-label>
                <ion-text class="fs-3 fw-500">{{ customer.name }}</ion-text>
                <ion-text class="fs-3 fw-500 text-primary mt-1">{{ customer.account_number }}</ion-text>
              </ion-label>
              <ion-checkbox
                color="primary"
                slot="start"
                :checked="customerBuyerIds.includes(customer.id) ? true : false"
              ></ion-checkbox>
            </ion-item>
          </div>
          <div v-if="showNoResultData" class="company-not-found mx-4">
            <ion-img src="/assets/images/not-found.svg" class="not-found-img" />
            <div class="not-found-text mt-3 mb-2">{{ $t('search_company_not_found') }}</div>
            <div
              class="try-different"
              v-html="
                $t('try_different_name', { company_search_name: search === '' ? refKeySearch : search })
              "
            ></div>
          </div>
          <ion-infinite-scroll
            v-if="showInfiniteScroll && showContent && !onSearchCompanies"
            threshold="100px"
            @ionInfinite="loadData"
            id="infinite-scroll"
          >
            <ion-infinite-scroll-content
              loading-spinner="bubbles"
              :loading-text="$t('loading_infinity_scroll')"
            >
            </ion-infinite-scroll-content>
          </ion-infinite-scroll>
        </ion-content>
        <ion-footer class="ion-no-border">
          <ion-toolbar v-if="!showContent && !isOpenAlert" style="--background: white">
            <ion-label>
              <ion-skeleton-text :animated="true" style="width: 100%; height: 42px"> </ion-skeleton-text>
              <ion-skeleton-text class="my-2" :animated="true" style="width: 100%; height: 42px">
              </ion-skeleton-text>
            </ion-label>
          </ion-toolbar>
          <ion-toolbar v-else style="--background: white">
            <ion-button class="foot-btn" expand="block" @click="handleSearchCompanies">{{
              $t('apply')
            }}</ion-button>
            <ion-button class="my-2 foot-btn-outline" expand="block" fill="outline" @click="closeModal">{{
              $t('cancel')
            }}</ion-button>
          </ion-toolbar>
        </ion-footer>
      </ion-page>
    </ion-modal>
    <ModalDateTime
      v-if="isOpenModalDateTime"
      :initialization_time="initializationTime"
      @handleSendTimeChange="handleSendTimeChange"
    />
  </ion-page>
</template>

<script>
import ModalDateTime from '@/components/DateTime/index.vue';
import { ACTIONS } from '@/modules/b2b/store/orders/purchase/actions';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { modalController } from '@ionic/vue';
import dayjs from 'dayjs';
import { caretDownOutline, closeCircle, closeOutline } from 'ionicons/icons';
import { ref } from 'vue';
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters } = createNamespacedHelpers('b2b/purchase');
export default {
  name: 'filter-modal',
  components: {
    ModalDateTime
  },
  props: {
    title: {
      type: String,
      default: null
    },
    customer: {
      type: Object,
      default: null
    }
  },
  setup() {
    const isOpenRef = ref(false);
    const setOpen = (state) => (isOpenRef.value = state);

    const isOpenLoadingref = ref(false);
    const setOpenLoading = (state) => (isOpenLoadingref.value = state);
    const isOpenModalDateTime = ref(false);
    const initializationTime = ref(null);
    const initializationModal = ref(null);
    return {
      setOpenLoading,
      isOpenLoadingref,
      isOpenRef,
      setOpen,
      caretDownOutline,
      closeOutline,
      closeCircle,
      isOpenModalDateTime,
      initializationTime,
      initializationModal,
      dayjs,
      Keyboard
    };
  },
  data() {
    return {
      statusIds: [],
      customerBuyerIds: [],
      startDeliveryDate: null,
      endDeliveryDate: null,
      isActive: false,
      selectAll: false,
      tempCustomerIds: [],
      resultCompanyName: '',
      offset: 0,
      listShowCustomer: [],
      tempCustomerName: [],
      showInfiniteScroll: true,
      listShowCustomerSearch: [],
      search: '',
      offsetSearch: 0,
      showContent: false,
      defaultLimit: 20,
      reRenderAlert: 1,
      isOpenAlert: false,
      isStartSearch: false,
      onSearchCompanies: false
    };
  },
  computed: {
    ...mapGetters([
      'customerAndStatus',
      'status',
      'loading',
      'params',
      'isLoadMore',
      'list',
      'list_customer_b2b',
      'totalCountCompany'
    ]),
    isShowList() {
      if (!this.isLoadMore) {
        return !this.loading;
      }
      return true;
    },
    formatDate() {
      return (date) => {
        return !date ? '' : dayjs(date).format('YYYY-MM-DD');
      };
    },
    showNoResultData() {
      return (
        this.isOpenAlert &&
        !this.loading &&
        this.listShowCustomerSearch.length <= 0 &&
        !this.onSearchCompanies
      );
    }
  },

  async mounted() {
    if (this.params) {
      let param = JSON.parse(JSON.stringify(this.params));
      this.statusIds = param.statusIds;
      this.customerBuyerIds = param.customerBuyerIds;
      this.endDeliveryDate = param.endDeliveryDate !== null ? param.endDeliveryDate : null;
      this.startDeliveryDate = param.startDeliveryDate !== null ? param.startDeliveryDate : null;
      if (!param.customerBuyerName) {
        this.tempCustomerName = [];
      } else {
        this.tempCustomerName = param.customerBuyerName;
      }
    }
    this.getCompanyName();
    this.getCustomerOrder('', this.offset);
    this.selectAll = false;
  },
  methods: {
    ...mapActions([
      ACTIONS.RELOAD_PURCHASE,
      ACTIONS.B2B_GET_CUSTOMERS_ORDERS,
      ACTIONS.GET_TOTAL_COUNT_PURCHASE_B2B_ORDER
    ]),
    handleOpenModalDateTime(params, redirect) {
      this.initializationModal = redirect;
      this.initializationTime = params;
      this.isOpenModalDateTime = false;
      this.$nextTick(() => {
        this.isOpenModalDateTime = true;
      });
    },
    handleSendTimeChange(e) {
      this.initializationModal === 'from' ? (this.startDeliveryDate = e) : (this.endDeliveryDate = e);
    },
    getCompanyName() {
      if (this.tempCustomerName.toString().length > 19) {
        this.resultCompanyName = this.tempCustomerName.toString().slice(0, 19) + '...';
      } else if (this.tempCustomerName.toString().length === 0) {
        this.resultCompanyName = '';
      } else {
        this.resultCompanyName = this.tempCustomerName.toString().slice(0, 19);
      }
    },
    async getCustomerOrder(search, offset) {
      await this[ACTIONS.B2B_GET_CUSTOMERS_ORDERS]({
        searchQueries: search,
        isIncludeTotalCount: true,
        limit: this.defaultLimit,
        offset: offset
      });
      if (this.status) {
        this.listShowCustomer = this.list_customer_b2b.customers;
      }
    },
    setOpenModal() {
      this.setOpen(true);
      this.getCustomerOrder('', 0);
      this.showInfiniteScroll = true;
      this.offsetSearch = 0;
      this.offset = 0;
      this.listShowCustomer = [];
      setTimeout(() => {
        this.showContent = true;
      }, 1200);
    },
    closeCompaniesFilter() {
      this.closeModal();
      this.setOpen(false);
    },
    closeModal() {
      this.listShowCustomerSearch = [];
      this.search = '';
      this.listShowCustomer = [];
      this.showContent = false;
      this.isOpenAlert = false;
      this.clearSearch();
      modalController.dismiss();
    },

    handleScroll(event) {
      if (event && Capacitor.isNativePlatform()) {
        this.Keyboard.hide();
      }
    },
    handleEnter() {
      if (Capacitor.isNativePlatform()) this.Keyboard.hide();
    },

    async loadData(e) {
      if (this.listShowCustomerSearch.length > 0) {
        this.offsetSearch = this.offsetSearch + parseInt(20);
        await this[ACTIONS.B2B_GET_CUSTOMERS_ORDERS]({
          searchQueries: this.search,
          isIncludeTotalCount: true,
          limit: this.defaultLimit,
          offset: this.offsetSearch
        });
        if (this.status) {
          this.listShowCustomerSearch = this.listShowCustomerSearch.concat(this.list_customer_b2b.customers);
          this.selectAllCompanies();
          this.list_customer_b2b.totalCount <= this.listShowCustomerSearch.length
            ? (this.showInfiniteScroll = false)
            : (this.showInfiniteScroll = true);
        }
      } else {
        this.offset = this.offset + parseInt(20);
        await this[ACTIONS.B2B_GET_CUSTOMERS_ORDERS]({
          searchQueries: '',
          isIncludeTotalCount: true,
          limit: this.defaultLimit,
          offset: this.offset
        });
        if (this.status) {
          this.listShowCustomer = this.listShowCustomer.concat(this.list_customer_b2b.customers);
          this.selectAllCompanies();
          this.list_customer_b2b.totalCount <= this.listShowCustomer.length
            ? (this.showInfiniteScroll = false)
            : (this.showInfiniteScroll = true);
        }
      }
      e.target.complete();
    },
    async onSearch(searchKey) {
      this.onSearchCompanies = true;
      this.offsetSearch = 0;
      await this[ACTIONS.B2B_GET_CUSTOMERS_ORDERS]({
        searchQueries: searchKey,
        isIncludeTotalCount: true,
        limit: this.defaultLimit,
        offset: this.offsetSearch
      });
      if (this.status) {
        if (this.totalCountCompany === 0) {
          this.isOpenAlert = true;
          this.reRenderAlert++;
        }
        this.listShowCustomerSearch = this.list_customer_b2b.customers;
        if (!this.selectAll) {
          this.customerBuyerIds = [];
          this.tempCustomerIds = [];
          this.tempCustomerName = [];
        } else {
          this.selectAllCompanies();
        }
        if (this.listShowCustomerSearch.length === 0) {
          this.showContent = false;
        } else {
          this.showContent = true;
        }
        this.onSearchCompanies = false;
      } else {
        this.onSearchCompanies = false;
      }
      const content = this.$refs.companiesList.$el;
      content.scrollToTop();
    },
    async updateSearchResults() {
      const currentSearchKey = this.$refs.searchInput.$el?.value;
      this.search = currentSearchKey;
      if (!this.search || this.search?.length <= 0) {
        this.showContent = true;
        this.listShowCustomerSearch = [];
        this.showInfiniteScroll = true;
      } else {
        await this.onSearch(this.search);
      }
      this.setSearchBarFocus();
      this.selectAll = false;
    },
    setSearchBarFocus() {
      this.$nextTick(() => {
        const searchbar = this.$refs.searchInput.$el;
        searchbar.setFocus();
      });
    },
    async applyFilter() {
      const payloadCustBuyerId = () => {
        if (this.customerBuyerIds.length >= 1) {
          return this.customerBuyerIds;
        } else {
          return this.params.customerBuyerIds;
        }
      };
      const payloadCustBuyerName = () => {
        if (this.tempCustomerName.length >= 1) {
          return this.tempCustomerName;
        } else {
          return this.params.customerBuyerName;
        }
      };
      await this[ACTIONS.RELOAD_PURCHASE]({
        statusIds: this.statusIds,
        customerBuyerIds: payloadCustBuyerId(),
        startDeliveryDate:
          (this.startDeliveryDate &&
            this.startDeliveryDate.slice(0, 11) + '00:00:00' + this.startDeliveryDate.slice(-6)) ||
          null,
        endDeliveryDate:
          (this.endDeliveryDate &&
            this.endDeliveryDate.slice(0, 11) + '23:59:59' + this.endDeliveryDate.slice(-6)) ||
          null,
        customerBuyerName: payloadCustBuyerName()
      });
      modalController.dismiss(null, 'apply');
    },
    async resetFilter() {
      this.statusIds = [];
      this.customerBuyerIds = [];
      this.tempCustomerIds = [];
      this.listShowCustomer = [];
      this.startDeliveryDate = null;
      this.endDeliveryDate = null;
      this.tempCustomerName = [];
      this.resultCompanyName = '';
      this.selectAll = false;
      await this[ACTIONS.RELOAD_PURCHASE]({
        statusIds: [],
        customerBuyerIds: [],
        startDeliveryDate: null,
        endDeliveryDate: null,
        customerBuyerName: []
      });
    },
    compareTime() {
      this.startDeliveryDate > this.endDeliveryDate &&
      this.startDeliveryDate !== null &&
      this.endDeliveryDate !== null
        ? (this.isActive = true)
        : (this.isActive = false);
    },
    handleAddCustomerId(id, name) {
      if (this.tempCustomerIds.includes(id)) {
        this.tempCustomerIds = this.tempCustomerIds.filter((checked) => checked !== id);
        this.tempCustomerName = this.tempCustomerName.filter((checkedName) => checkedName !== name);
      } else {
        this.tempCustomerIds.push(id);
        this.tempCustomerName.push(name);
      }
    },
    async selectAllCompanies() {
      if (this.selectAll) {
        const getCustomer =
          this.listShowCustomerSearch.length > 0 ? this.listShowCustomerSearch : this.listShowCustomer;
        this.tempCustomerIds = getCustomer.map((item) => item.id);
        this.tempCustomerName = getCustomer.map((item) => item.name);
      } else {
        this.tempCustomerIds = [];
      }
    },
    setSearch(params) {
      this.isStartSearch = params;
    },
    clearSearch() {
      if (this.$refs.searchInput) {
        this.$refs.searchInput.$el.value = '';
      }
      this.search = '';
      this.isOpenAlert = false;
      const content = this.$refs.companiesList?.$el;
      content?.scrollToTop();
    },
    handleSearchCompanies() {
      this.customerBuyerIds = this.tempCustomerIds;
      this.getCompanyName();
      this.listShowCustomerSearch = [];
      this.search = '';
      this.listShowCustomer = [];
      this.closeModal();
    },
    handleGetTotalCountOrder() {
      this[ACTIONS.GET_TOTAL_COUNT_PURCHASE_B2B_ORDER]({
        params: {
          offset: 0,
          isIncludeTotalCount: true
        }
      });
    }
  },
  watch: {
    startDeliveryDate() {
      this.compareTime();
    },
    endDeliveryDate() {
      this.compareTime();
    },
    list() {
      this.setOpenLoading(false);
      this.handleGetTotalCountOrder();
    },
    list_customer_b2b() {
      this.customerBuyerIds =
        this.listShowCustomerSearch.length > 0
          ? this.listShowCustomerSearch.map((item) => item.id)
          : this.listShowCustomer.map((item) => item.id);
      this.tempCustomerIds = this.customerBuyerIds;
    },
    search() {
      setTimeout(() => {
        this.refKeySearch = this.search;
      }, 1000);
    },
    tempCustomerIds() {
      this.customerBuyerIds = this.tempCustomerIds;
    }
  }
};
</script>

<style scoped lang="scss">
.name {
  color: #64ae0f;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
}

#hidden-scroll::part(scroll) {
  overflow: hidden;
}

ion-label,
ion-select,
ion-datetime {
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 14px !important;
  line-height: 18px !important;
  line-height: 22px;
}
ion-text {
  padding-top: 4px;
  display: block;
}
.searchbar-input {
  height: 100px;
}
.btn-footer {
  display: flex;
}
#content-companies {
  --padding-start: 14px;
  --padding-end: 14px;
}
.item-danger {
  color: red !important;
}
#disable-click {
  pointer-events: none;
}
.foot-btn-outline {
  --border-radius: 10px;
  --color: #00676a;
}
.foot-btn {
  --border-radius: 10px;
  --background: #00676a;
  --color: white;
}
.close-modal {
  font-size: 20px;
  color: #92949c;
  background: #989aa226;
  padding: 3px;
  border-radius: 20px;
}
.selected-count {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: right;
  letter-spacing: 0.5px;
  color: #eb8c31;
}
.adjust-right {
  margin-right: -10px;
}
.adjust-right-2 {
  margin-right: -6px;
}
ion-searchbar {
  --background: transparent;
  --icon-color: #00676a;
  border: 3px solid transparent;
  margin-top: -12px;
  font-size: 15px;
}
.new-search {
  border: 1.3px solid #00676a;
  border-radius: 12px;
  height: 45px;
  background: #ffffff;
  color: black;
  width: 80vw;
}
.new-search-off {
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  height: 45px;
  background: #f5f5f5;
  color: #9e9e9e;
  width: 80vw;
}
.search {
  padding-right: 0px;
  padding-left: 0px;
}
.company-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  margin-top: 6vh;
}
.not-found-img {
  height: 120px;
  width: 120px;
}
.not-found-text {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.7px;
}
.try-different {
  text-align: center;
}
.item-company {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  & > ion-checkbox {
    margin-top: 12px;
  }
}
.load-search {
  --background: transparent;
}
ion-skeleton-text {
  --border-radius: 8px;
}
.search-company {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-content: center;
  justify-content: space-between;
}
.clear-search {
  margin-top: 3px;
  margin-right: 4px;
  font-size: 28px;
  color: #9e9e9e;
}
ion-spinner {
  position: absolute;
  left: 45%;
  z-index: 999999;
}
</style>
